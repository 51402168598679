import { ReduxDispatch } from '../../models/ReduxProps';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import ActionUtility from '../../utilities/ActionUtility';
import RecipesEffect from '../recipes/RecipesEffect';
import ICollectionState from './models/ICollectionState';
import TertiaryCollectionEffect from './TertiaryCollectionEffect';

type ActionUnion = undefined | HttpErrorResponseModel;

export default class TertiaryCollectionAction {
    static TOGGLE_OPEN = 'TertiaryCollectionAction.TOGGLE_OPEN';
    static TOGGLE_RECIPE = 'TertiaryCollectionAction.TOGGLE_RECIPE_SELECT';
    static TOGGLE_LIST = 'TertiaryCollectionAction.TOGGLE_LIST';
    static UPDATE_RECIPE = 'TertiaryCollectionAction.UPDATE_RECIPE';
    static UPDATE_LIST = 'TertiaryCollectionAction.UPDATE_LIST';
    static UPDATE_COLLECTION = 'TertiaryCollectionAction.UPDATE_COLLECTION';
    static REQUEST_NEW_COLLECTION = 'TertiaryCollectionAction.REQUEST_NEW_COLLECTION';
    static REQUEST_NEW_COLLECTION_FINISHED = 'TertiaryCollectionAction.REQUEST_NEW_COLLECTION_FINISHED';
    static REQUEST_COLLECTION = 'TertiaryCollectionAction.REQUEST_COLLECTION';
    static REQUEST_COLLECTION_FINISHED = 'TertiaryCollectionAction.REQUEST_COLLECTION_FINISHED';
    static REQUEST_SEARCH_COLLECTIONS = 'TertiaryCollectionAction.REQUEST_SEARCH_COLLECTIONS';
    static REQUEST_SEARCH_COLLECTIONS_FINISHED = 'TertiaryCollectionAction.REQUEST_SEARCH_COLLECTIONS_FINISHED';
    static REQUEST_ADDITIONAL_RECIPE_INFO = 'TertiaryCollectionAction.REQUEST_ADDITIONAL_RECIPE_INFO';
    static REQUEST_ADDITIONAL_RECIPE_INFO_FINISHED = 'TertiaryCollectionAction.REQUEST_ADDITIONAL_RECIPE_INFO_FINISHED';
    static CLEAR = 'TertiaryCollectionAction.CLEAR';
    static REQUEST_COLLECTION_UPDATE = 'TertiaryCollectionAction.REQUEST_COLLECTION_UPDATE';
    static REQUEST_COLLECTION_UPDATE_FINISHED = 'TertiaryCollectionAction.REQUEST_COLLECTION_UPDATE_FINISHED';
    static UPDATE_SEARCH = 'TertiaryCollectionAction.UPDATE_SEARCH';
    static UPDATE_SIDEBAR = 'TertiaryCollectionAction.UPDATE_SIDEBAR';
    static REQUEST_PC_EXPORT = 'TertiaryCollectionAction.REQUEST_PC_EXPORT';
    static REQUEST_PC_EXPORT_FINISHED = 'TertiaryCollectionAction.REQUEST_PC_EXPORT_FINISHED';
    static REQUEST_ROCHE_EXPORT = 'TertiaryCollectionAction.REQUEST_ROCHE_EXPORT';
    static REQUEST_ROCHE_EXPORT_FINISHED = 'TertiaryCollectionAction.REQUEST_ROCHE_EXPORT_FINISHED';
    static REQUEST_DOLE_EXPORT = 'TertiaryCollectionAction.REQUEST_DOLE_EXPORT';
    static REQUEST_DOLE_EXPORT_FINISHED = 'TertiaryCollectionAction.REQUEST_DOLE_EXPORT_FINISHED';

    static toggleRecipe(recipeId: string, orgId: string, title?: string, orgName?: string, state?: string) {
        return ActionUtility.createAction(TertiaryCollectionAction.TOGGLE_RECIPE, {
            title: title,
            recipeId: recipeId,
            orgId: orgId,
            orgName: orgName,
            state: state,
        });
    }

    static toggleList(id: string) {
        return ActionUtility.createAction(TertiaryCollectionAction.TOGGLE_LIST, { id: id });
    }

    static updateRecipe(id: string, key: string, value: any) {
        return ActionUtility.createAction(TertiaryCollectionAction.UPDATE_RECIPE, { id: id, key: key, value: value });
    }

    static updateList(id: string, key: string, value: string) {
        return ActionUtility.createAction(TertiaryCollectionAction.UPDATE_LIST, { id: id, key: key, value: value });
    }

    static updateCollection(key: string, value: string | boolean) {
        return ActionUtility.createAction(TertiaryCollectionAction.UPDATE_COLLECTION, { key: key, value: value });
    }

    static toggleOpen(state: boolean) {
        return ActionUtility.createAction(TertiaryCollectionAction.TOGGLE_OPEN, state);
    }

    static requestNewCollection() {
        return async (dispatch: ReduxDispatch<ActionUnion>, getState: any) => {
            const active = getState().tertiaryCollections.active;
            const data = {
                invoker_id: getState().auth.user_id,
                name: active.name,
                link: active.link,
                affiliated_vendor: active.affiliatedVendor,
                lists: active.lists,
                recipes: active.recipes
                    .slice()
                    .sort((a: { title: string }, b: { title: string }) => (a.title < b.title ? -1 : 1))
                    .map((recipe: { recipeId: string; organizationId: string; listId: string }) => {
                        return {
                            recipe_id: recipe.recipeId,
                            organization_id: recipe.organizationId,
                            list_id: recipe.listId,
                        };
                    }),
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                TertiaryCollectionAction.REQUEST_NEW_COLLECTION,
                TertiaryCollectionEffect.requestNewCollection,
                { notify: false },
                data
            );
        };
    }

    static requestCollectionUpdate() {
        return async (dispatch: ReduxDispatch<ActionUnion>, getState: any) => {
            const active = getState().tertiaryCollections.active;
            const data = {
                collection_id: active.id,
                invoker_id: getState().auth.user_id,
                name: active.name,
                link: active.link,
                affiliated_vendor: active.affiliatedVendor,
                lists: active.lists,
                recipes: active.recipes
                    .slice()
                    .sort((a: { title: string }, b: { title: string }) => (a.title < b.title ? -1 : 1))
                    .map((recipe: { recipeId: string; organizationId: string; listId: string }) => {
                        return {
                            recipe_id: recipe.recipeId,
                            organization_id: recipe.organizationId,
                            list_id: recipe.listId,
                        };
                    }),
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                TertiaryCollectionAction.REQUEST_COLLECTION_UPDATE,
                TertiaryCollectionEffect.requestCollectionUpdate,
                { notify: false },
                data
            );
        };
    }

    static requestAdditionalRecipeInfo(data: { recipe_id: string; organization_id: string }) {
        return async (dispatch: ReduxDispatch<ActionUnion>, getState: any) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                TertiaryCollectionAction.REQUEST_ADDITIONAL_RECIPE_INFO,
                TertiaryCollectionEffect.requestAdditionalRecipeInfo,
                { notify: false },
                data
            );
        };
    }

    static requestCollection(data: { id: string }) {
        return async (dispatch: ReduxDispatch<ActionUnion>, getState: any) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                TertiaryCollectionAction.REQUEST_COLLECTION,
                TertiaryCollectionEffect.requestCollection,
                { notify: false },
                data
            );
        };
    }

    static updateSearch(data: { key: string; value: any }) {
        return ActionUtility.createAction(TertiaryCollectionAction.UPDATE_SEARCH, data);
    }

    static updateSidebar(data: { key: string; value: any }) {
        return ActionUtility.createAction(TertiaryCollectionAction.UPDATE_SIDEBAR, data);
    }

    static searchCollections() {
        return async (dispatch: ReduxDispatch<ActionUnion>, getState: any) => {
            const search = getState().tertiaryCollections.search;

            await ActionUtility.createThunkEffect(
                dispatch,
                TertiaryCollectionAction.REQUEST_SEARCH_COLLECTIONS,
                TertiaryCollectionEffect.searchCollections,
                { notify: false },
                {
                    name: search.name,
                    page: search.page,
                    count: search.count,
                    affiliated_vendor: search.affiliatedVendor,
                }
            );
        };
    }

    static requestPCExport() {
        return async (dispatch: ReduxDispatch<ActionUnion>, getState: any) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                TertiaryCollectionAction.REQUEST_PC_EXPORT,
                TertiaryCollectionEffect.requestPCExport,
                { notify: true, title: 'Exporting Price Chopper' },
                {
                    recipes: getState().tertiaryCollections.active.recipes.map((recipe: { recipeId: string }) => recipe.recipeId),
                }
            );
        };
    }

    static requestMealPlanPCExport() {
        return async (dispatch: ReduxDispatch<ActionUnion>, getState: any) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                TertiaryCollectionAction.REQUEST_PC_EXPORT,
                TertiaryCollectionEffect.requestMealPlanPCExport,
                { notify: true, title: 'Exporting Price Chopper Meal Plan' },
                {
                    recipes: getState().tertiaryCollections.active.recipes.map((recipe: { recipeId: string }) => recipe.recipeId),
                }
            );
        };
    }

    static requestRocheExport() {
        return async (dispatch: ReduxDispatch<ActionUnion>, getState: any) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                TertiaryCollectionAction.REQUEST_ROCHE_EXPORT,
                TertiaryCollectionEffect.requestRocheExport,
                { notify: true, title: 'Exporting Roche' },
                {
                    recipes: getState().tertiaryCollections.active.recipes.map((recipe: { recipeId: string }) => recipe.recipeId),
                }
            );
        };
    }

    static requestDoleExport() {
        return async (dispatch: ReduxDispatch<ActionUnion>, getState: any) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                TertiaryCollectionAction.REQUEST_DOLE_EXPORT,
                TertiaryCollectionEffect.requestDoleExport,
                { notify: true, title: 'Exporting Dole' },
                {
                    recipes: getState().tertiaryCollections.active.recipes.map((recipe: { recipeId: string }) => recipe.recipeId),
                }
            );
        };
    }

    static clear() {
        return ActionUtility.createAction(TertiaryCollectionAction.CLEAR, null);
    }
}
