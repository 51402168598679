import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import RecipeSnippet from './models/RecipeSnippet';
import CreateRecipeResponse from '../new-recipe/models/CreateRecipeResponse';
import RecipeStateSnippet from '../recipe-tracker/models/RecipeStateSnippet';

export default class RecipeSearchEffect {
    static async dynamicSearch(data) {
        const endpoint = environment.api.recipeSearch.replace(':endpoint', 'dynamic');

        return EffectUtility.getToModel(RecipeSnippet, endpoint, data);
    }

    static async requestAvailableStates() {
        const endpoint = environment.api.recipeSearch.replace(':endpoint', 'state/all');

        return EffectUtility.getToModel(RecipeStateSnippet, endpoint);
    }
}
