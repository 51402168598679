import { BaseModel } from 'sjs-base-model';

export default class IRequestAllResponse extends BaseModel {
    collections = [
        {
            id: '',
            affiliated_vendor: '',
            name: '',
            recipe_count: '',
        },
    ];

    /*
     * Client-Side properties (Not from API)
     */

    // noneApiProperties = null;

    constructor(data: any) {
        super();

        this.update(data);
    }
}
