import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        {
            "title": "Pot Pie",
            "recipe_id": "1a78bb679a8bae1b48937e50994f6b79"
        }
    }
 */

export default class CreateRecipeResponse extends BaseModel {
    title = '';
    recipe_id = '';

    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
