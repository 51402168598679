import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PrivateRouteView from './PrivateRouteView';

class PrivateRouteContainer extends Component {
    render() {
        return <PrivateRouteView is_authenticated={this.props.auth.is_authenticated} path={this.props.path} component={this.props.component} />;
    }
}

PrivateRouteContainer.propTypes = {};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, null)(PrivateRouteContainer);
