import React from 'react';
import PropTypes from 'prop-types';
import './Auth.scss';
import VimaxLogo from '../../../images/vimax-logo.png';
import UserIcon from '../../../images/default-user-icon.png';

const AuthView = (props) => {
    return (
        <div className={'auth-wrapper'}>
            <div className={'form-wrapper'} onSubmit={props.handleSubmit}>
                <h1>Sign In</h1>
                <img src={UserIcon} />
                <form>
                    <input name={'email'} type={'text'} onChange={props.handleFormChange} />
                    <input name={'password'} type={'password'} onChange={props.handleFormChange} />
                    <a>Forgot Password?</a>
                    <button>SIGN IN</button>
                </form>
                <a>Create Account</a>
            </div>
        </div>
    );
};

AuthView.propTypes = {};

export default AuthView;
