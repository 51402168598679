import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        "success": "success",
        "organizations": [
            {
                "organization_id": "20d4f7d2798e61306706e9aed086053f",
                "organization_name": "Vimax"
            },
            {
                "organization_id": "4e32fe731b93f66833a700b79580b8b6",
                "organization_name": "Roche"
            }
        ]
    }
 */

export default class RequestOrganizationsResponse extends BaseModel {
    organizations = '';

    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
