import ActionUtility from '../../utilities/ActionUtility';
import ExportEffect from './ExportEffect';

export default class ExportAction {
    static REQUEST_EXPORT_TO_TEXT = 'ExportAction.REQUEST_EXPORT_TO_TEXT';
    static REQUEST_EXPORT_TO_TEXT_FINISHED = 'ExportAction.REQUEST_EXPORT_TO_TEXT_FINISHED';

    static REQUEST_EXPORT_TO_DOLE = 'ExportAction.REQUEST_EXPORT_TO_DOLE';
    static REQUEST_EXPORT_TO_DOLE_FINISHED = 'ExportAction.REQUEST_EXPORT_TO_DOLE_FINISHED';

    static REQUEST_EXPORT_TO_WP = 'ExportAction.REQUEST_EXPORT_TO_WP';
    static REQUEST_EXPORT_TO_WP_FINISHED = 'ExportAction.REQUEST_EXPORT_TO_WP_FINISHED';

    static exportToText(data) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, ExportAction.REQUEST_EXPORT_TO_TEXT, ExportEffect.exportToText, { notify: true }, data);
        };
    }

    static exportToDole(data) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, ExportAction.REQUEST_EXPORT_TO_DOLE, ExportEffect.exportToDole, { notify: true }, data);
        };
    }

    static exportToWp(data) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, ExportAction.REQUEST_EXPORT_TO_WP, ExportEffect.exportToWp, { notify: true }, data);
        };
    }
}
