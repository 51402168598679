import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const PrivateRouteView = ({ component: Component, is_authenticated, path, ...rest }) => {
    return (
        <Route
            {...rest}
            path={path}
            render={(props) =>
                is_authenticated === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

PrivateRouteView.propTypes = {
    is_authenticated: PropTypes.bool.isRequired,
};

export default PrivateRouteView;
