import HttpUtility from '../../utilities/HttpUtility';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import TokenModel from './models/TokenModel';
import ValidateTokenResponse from './models/ValidateTokenResponse';

export default class AuthEffect {
    static async requestUserAuthentication(data) {
        const endpoint = environment.api.auth;
        return EffectUtility.postToModel(TokenModel, endpoint, data);
    }

    static async requestTokenValidation(data) {
        const endpoint = environment.api.validateToken;
        return EffectUtility.postToModel(ValidateTokenResponse, endpoint, data);
    }
}
