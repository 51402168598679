import ActionUtility from '../../utilities/ActionUtility';
import AuthEffect from './AuthEffect';

export default class AuthAction {
    static HANDLE_FORM_CHANGE = 'AuthAction.HANDLE_FORM_CHANGE';

    static HANDLE_MOCK_ORG_CHANGE = 'AuthAction.HANDLE_MOCK_ORG_CHANGE';

    static REQUEST_USER_AUTHENTICATION = 'AuthAction.REQUEST_USER_AUTHENTICATION';
    static REQUEST_USER_AUTHENTICATION_FINISHED = 'AuthAction.REQUEST_USER_AUTHENTICATION_FINISHED';

    static REQUEST_TOKEN_VALIDATION = 'AuthAction.REQUEST_TOKEN_VALIDATION';
    static REQUEST_TOKEN_VALIDATION_FINISHED = 'AuthAction.REQUEST_TOKEN_VALIDATION_FINISHED';

    static handleFormChange(data) {
        return ActionUtility.createAction(AuthAction.HANDLE_FORM_CHANGE, data);
    }

    static handleMockOrgChange(mockOrgId) {
        return ActionUtility.createAction(AuthAction.HANDLE_MOCK_ORG_CHANGE, mockOrgId);
    }

    static requestUserAuthentication(data) {
        console.log('HERE');
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                AuthAction.REQUEST_USER_AUTHENTICATION,
                AuthEffect.requestUserAuthentication,
                { notify: true, title: 'Authenticating' },
                data
            );
        };
    }

    static requestTokenValidation(data) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                AuthAction.REQUEST_TOKEN_VALIDATION,
                AuthEffect.requestTokenValidation,
                { notify: false },
                data
            );
        };
    }
}
