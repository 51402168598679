import { BaseModel } from 'sjs-base-model';

export default class ICreateCollectionResponse extends BaseModel {
    public readonly collection_id: string = '';

    constructor(data: Partial<ICreateCollectionResponse>) {
        super();

        this.update(data);
    }

    public update(data: Partial<ICreateCollectionResponse>): void {
        super.update(data);
    }
    /*
     * Client-Side properties (Not from API)
     */
}
