import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import UpdateRecipeStateResponse from './models/UpdateRecipeStateResponse';

export default class RecipeTrackerEffect {
    static async updateRecipeState(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'update-state');

        return EffectUtility.postToModel(UpdateRecipeStateResponse, endpoint, data);
    }
}
