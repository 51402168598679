import ActionUtility from '../../utilities/ActionUtility';
import RecipeSearchEffect from './RecipeSearchEffect';
import RecipeTrackerEffect from '../recipe-tracker/RecipeTrackerEffect';

export default class RecipeSearchAction {
    static UPDATE_SEARCH_CRITERIA = 'RecipeSearchAction.UPDATE_SEARCH_CRITERIA';

    static REQUEST_DYNAMIC_SEARCH = 'RecipeSearchAction.REQUEST_DYNAMIC_SEARCH';
    static REQUEST_DYNAMIC_SEARCH_FINISHED = 'RecipeSearchAction.REQUEST_DYNAMIC_SEARCH_FINISHED';

    static REQUEST_AVAILABLE_STATES = 'RecipeSearchAction.REQUEST_AVAILABLE_STATES';
    static REQUEST_AVAILABLE_STATES_FINISHED = 'RecipeSearchAction.REQUEST_AVAILABLE_STATES_FINISHED';

    static updateSearchCriteria(data) {
        const current = JSON.parse(localStorage.getItem('searchCriteria'));
        if (current) {
            localStorage.setItem('searchCriteria', JSON.stringify({ ...current, [data.key]: data.value }));
        }
        return ActionUtility.createAction(RecipeSearchAction.UPDATE_SEARCH_CRITERIA, data);
    }

    static dynamicSearch(data) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                RecipeSearchAction.REQUEST_DYNAMIC_SEARCH,
                RecipeSearchEffect.dynamicSearch,
                { notify: false },
                data
            );
        };
    }

    static requestAvailableStates() {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, RecipeSearchAction.REQUEST_AVAILABLE_STATES, RecipeSearchEffect.requestAvailableStates, {
                notify: false,
            });
        };
    }
}
