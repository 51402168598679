import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import UserModel from './models/UserModel';

export default class UserEffect {
    static async requestUsers(data) {
        const endpoint = environment.api.users;

        return EffectUtility.getToModel(UserModel, endpoint, data);
    }
}
