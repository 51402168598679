import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        "user_id": 1,
        "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ2aW1heC1jb250ZW50LXBybyIsInN1YiI6MSwiaWF0IjoxNTg4MjA1NjgzLCJleHAiOjE1ODg0MjE2ODN9.uDd9nAteHHHofYON3mfgd8kZL58R0W2rGIJQnXdcoXc"
    }
 */
export default class TokenModel extends BaseModel {
    success = '';
    user_id = '';
    organization_id = '';
    api_token = '';

    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
