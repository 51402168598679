import ActionUtility from '../../utilities/ActionUtility';
import RecipesEffect from './RecipesEffect';

export default class RecipesAction {
    static CREATE_RECIPE = 'RecipesAction.CREATE_RECIPE';
    static REQUEST_SINGLE_RECIPE = 'RecipesAction.REQUEST_SINGLE_RECIPE';
    static REQUEST_SINGLE_RECIPE_FINISHED = 'RecipesAction.REQUEST_SINGLE_RECIPE_FINISHED';
    static REQUEST_STATE_LOG = 'RecipesAction.REQUEST_STATE_LOG';
    static REQUEST_STATE_LOG_FINISHED = 'RecipesAction.REQUEST_STATE_LOG_FINISHED';
    static HANDLE_RECIPE_CHANGE = 'RecipesAction.HANDLE_RECIPE_CHANGE';
    static HANDLE_LIST_CHANGE = 'RecipesAction.HANDLE_LIST_CHANGE';
    static HANDLE_NESTED_CHANGE = 'RecipesAction.HANDLE_NESTED_CHANGE';
    static REORDER_LIST = 'RecipesAction.REORDER_LIST';
    static ADD_RECORD = 'RecipesAction.ADD_RECORD';
    static ADD_NUTRIENT_RECORD = 'RecipesAction.ADD_NUTRIENT_RECORD';
    static SELECT_RECORD = 'RecipesAction.SELECT_RECORD';
    static SELECT_LIST = 'RecipesAction.SELECT_LIST';
    static DELETE_RECORDS = 'RecipesAction.DELETE_RECORDS';
    static ADD_LIST = 'RecipesAction.ADD_LIST';
    static DELETE_LISTS = 'RecipesAction.DELETE_LISTS';
    static ADD_KEYWORD = 'RecipesAction.ADD_KEYWORD';
    static DELETE_KEYWORD = 'RecipesAction.DELETE_KEYWORD';
    static REQUEST_IMAGE_NAMES_UPDATE = 'RecipesAction.REQUEST_IMAGE_NAMES_UPDATE';
    static REQUEST_IMAGE_NAMES_UPDATE_FINISHED = 'RecipesAction.REQUEST_IMAGE_NAMES_UPDATE_FINISHED';
    static HANDLE_IMAGE_NAME_CHANGE = 'RecipesAction.HANDLE_IMAGE_NAME_CHANGE';
    static UPDATE_RECIPE = 'RecipesAction.UPDATE_RECIPE';
    static REQUEST_TITLE_UPDATE = 'RecipesAction.REQUEST_TITLE_UPDATE';
    static REQUEST_TITLE_UPDATE_FINISHED = 'RecipesAction.REQUEST_TITLE_UPDATE_FINISHED';
    static REQUEST_UPDATE_DESCRIPTION = 'RecipesAction.REQUEST_UPDATE_DESCRIPTION';
    static REQUEST_UPDATE_DESCRIPTION_FINISHED = 'RecipesAction.REQUEST_UPDATE_DESCRIPTION_FINISHED';
    static REQUEST_SERVINGS_UPDATE = 'RecipesAction.REQUEST_SERVINGS_UPDATE';
    static REQUEST_SERVINGS_UPDATE_FINISHED = 'RecipesAction.REQUEST_SERVINGS_UPDATE_FINISHED';
    static REQUEST_PREP_INFO_UPDATE = 'RecipesAction.REQUEST_PREP_INFO_UPDATE';
    static REQUEST_PREP_INFO_UPDATE_FINISHED = 'RecipesAction.REQUEST_PREP_INFO_UPDATE_FINISHED';
    static REQUEST_INGREDIENTS_UPDATE = 'RecipesAction.REQUEST_INGREDIENTS_UPDATE';
    static REQUEST_INGREDIENTS_UPDATE_FINISHED = 'RecipesAction.REQUEST_INGREDIENTS_UPDATE_FINISHED';
    static REQUEST_DIRECTIONS_UPDATE = 'RecipesAction.REQUEST_DIRECTIONS_UPDATE';
    static REQUEST_DIRECTIONS_UPDATE_FINISHED = 'RecipesAction.REQUEST_DIRECTIONS_UPDATE_FINISHED';
    static REQUEST_NUTRIENTS_UPDATE = 'RecipesAction.REQUEST_NUTRIENTS_UPDATE';
    static REQUEST_NUTRIENTS_UPDATE_FINISHED = 'RecipesAction.REQUEST_NUTRIENTS_UPDATE_FINISHED';
    static REQUEST_TIPS_UPDATE = 'RecipesAction.REQUEST_TIPS_UPDATE';
    static REQUEST_TIPS_UPDATE_FINISHED = 'RecipesAction.REQUEST_TIPS_UPDATE_FINISHED';
    static REQUEST_KEYWORDS_UPDATE = 'RecipesAction.REQUEST_KEYWORDS_UPDATE';
    static REQUEST_KEYWORDS_UPDATE_FINISHED = 'RecipesAction.REQUEST_KEYWORDS_UPDATE_FINISHED';
    static EXPORT_SALES_TEAM = 'RecipesAction.EXPORT_SALES_TEAM';
    static EXPORT_SALES_TEAM_FINISHED = 'RecipesAction.EXPORT_SALES_TEAM_FINISHED';
    static REQUEST_RECIPE_DELETION = 'RecipesAction.REQUEST_RECIPE_DELETION';
    static REQUEST_RECIPE_DELETION_FINISHED = 'RecipesAction.REQUEST_RECIPE_DELETION_FINISHED';
    static REQUEST_RECIPE_ARCHIVE = 'RecipesAction.REQUEST_RECIPE_ARCHIVE';
    static REQUEST_RECIPE_ARCHIVE_FINISHED = 'RecipesAction.REQUEST_RECIPE_ARCHIVE_FINISHED';
    static REQUEST_EXPORT_PDF = 'RecipesAction.REQUEST_EXPORT_PDF';
    static REQUEST_EXPORT_PDF_FINISHED = 'RecipesAction.REQUEST_EXPORT_PDF_FINISHED';
    static REQUEST_UPDATE_CLIENT_SUPPLIED_STATUS = 'RecipesAction.REQUEST_UPDATE_CLIENT_SUPPLIED_STATUS';
    static REQUEST_UPDATE_CLIENT_SUPPLIED_STATUS_FINISHED = 'RecipesAction.REQUEST_UPDATE_CLIENT_SUPPLIED_STATUS_FINISHED';


    static updateImageNames(data) {
        return async (dispatch, getState) => {
            const request = {
                api_token: getState().auth.api_token,
                recipe_id: data.recipeId,
                invoker_id: getState().auth.user_id,
                image_names: data.image_names,
                mock_organization_id: data.orgId,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_IMAGE_NAMES_UPDATE,
                RecipesEffect.updateImageNames,
                { notify: true },
                request
            );
        };
    }

    // @TODO Remove this method
    static requestSingleRecipe(data) {
        return async (dispatch, getState) => {
            const orgId = data.mock_org_id ? data.mock_org_id : data.organization_id;

            data = {
                recipe_id: data.recipe_id,
                organization_id: orgId,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_SINGLE_RECIPE,
                RecipesEffect.requestSingleRecipe,
                { notify: false },
                data
            );
        };
    }

    static requestStateLog(recipeId, orgId) {
        return async (dispatch, getState) => {
            const request = {
                recipe_id: recipeId,
                organization_id: orgId,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_STATE_LOG,
                RecipesEffect.requestStateLog,
                { notify: false },
                request
            );
        };
    }

    static updateRecipe() {
        return ActionUtility.createAction(RecipesAction.UPDATE_RECIPE);
    }

    static updateTitle(data) {
        return async (dispatch, getState) => {
            const request = {
                api_token: getState().auth.api_token,
                recipe_id: data.recipe_id,
                invoker_id: getState().auth.user_id,
                title: data.title,
                mock_organization_id: data.mock_org_id,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_TITLE_UPDATE,
                RecipesEffect.updateTitle,
                { notify: false },
                request
            );
        };
    }

    static updateDescription(data) {
        return async (dispatch, getState) => {
            const request = {
                api_token: getState().auth.api_token,
                recipe_id: data.recipe_id,
                invoker_id: getState().auth.user_id,
                description: data.description,
                mock_organization_id: data.mock_org_id,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_DIRECTIONS_UPDATE,
                RecipesEffect.updateDescription,
                { notify: false },
                request
            );
        };
    }

    static updateServings(data) {
        return async (dispatch, getState) => {
            const sortedLists = RecipesAction._sortRecords(data.servings);

            const request = {
                recipe_id: data.recipe_id,
                invoker_id: getState().auth.user_id,
                servings: sortedLists,
                api_token: getState().auth.api_token,
                mock_organization_id: data.mock_org_id,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_SERVINGS_UPDATE,
                RecipesEffect.updateServings,
                { notify: false },
                request
            );
        };
    }

    static updatePrepInfo(data) {
        return async (dispatch, getState) => {
            const sortedLists = RecipesAction._sortRecords(data.prep_info);

            const request = {
                recipe_id: data.recipe_id,
                invoker_id: getState().auth.user_id,
                prep_info: sortedLists,
                api_token: getState().auth.api_token,
                mock_organization_id: data.mock_org_id,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_PREP_INFO_UPDATE,
                RecipesEffect.updatePrepInfo,
                { notify: false },
                request
            );
        };
    }

    static updateIngredients(data) {
        return async (dispatch, getState) => {
            const sortedLists = RecipesAction._sortRecords(data.ingredients);

            const request = {
                recipe_id: data.recipe_id,
                invoker_id: getState().auth.user_id,
                ingredients: sortedLists,
                api_token: getState().auth.api_token,
                mock_organization_id: data.mock_org_id,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_INGREDIENTS_UPDATE,
                RecipesEffect.updateIngredients,
                { notify: false },
                request
            );
        };
    }

    static updateDirections(data) {
        return async (dispatch, getState) => {
            const sortedLists = RecipesAction._sortRecords(data.directions);

            const request = {
                recipe_id: data.recipe_id,
                invoker_id: getState().auth.user_id,
                directions: sortedLists,
                api_token: getState().auth.api_token,
                mock_organization_id: data.mock_org_id,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_DIRECTIONS_UPDATE,
                RecipesEffect.updateDirections,
                { notify: false },
                request
            );
        };
    }

    static updateNutrients(data) {
        return async (dispatch, getState) => {
            const request = {
                recipe_id: data.recipe_id,
                invoker_id: getState().auth.user_id,
                nutrients: data.nutrients,
                api_token: getState().auth.api_token,
                mock_organization_id: data.mock_org_id,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_NUTRIENTS_UPDATE,
                RecipesEffect.updateNutrients,
                { notify: false },
                request
            );
        };
    }

    static updateTips(data) {
        return async (dispatch, getState) => {
            const sortedLists = RecipesAction._sortRecords(data.chef_tips);

            const request = {
                recipe_id: data.recipe_id,
                invoker_id: getState().auth.user_id,
                tips: sortedLists,
                api_token: getState().auth.api_token,
                mock_organization_id: data.mock_org_id,
            };

            await ActionUtility.createThunkEffect(dispatch, RecipesAction.REQUEST_TIPS_UPDATE, RecipesEffect.updateTips, { notify: false }, request);
        };
    }

    static updateKeywords(data) {
        return async (dispatch, getState) => {
            const keywords = data.keywords;

            const request = {
                recipe_id: data.recipe_id,
                invoker_id: getState().auth.user_id,
                keywords: keywords,
                api_token: getState().auth.api_token,
                mock_organization_id: data.mock_org_id,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_KEYWORDS_UPDATE,
                RecipesEffect.updateKeywords,
                { notify: false },
                request
            );
        };
    }

    static exportPDF(data) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_EXPORT_PDF,
                RecipesEffect.exportPDF,
                { notify: true, title: 'Exporting PDF' },
                data
            );
        };
    }

    // @todo can be deprecated once everything is moved to exportPDF() function
    static exportSalesTeam(recipeId) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.EXPORT_SALES_TEAM,
                RecipesEffect.exportSalesTeam,
                { notify: false },
                recipeId
            );
        };
    }

    static deleteRecipe(recipeId) {
        return async (dispatch, getState) => {
            const data = {
                recipe_id: recipeId,
                invoker_id: getState().auth.user_id,
                api_token: getState().auth.api_token,
            };
            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_RECIPE_DELETION,
                RecipesEffect.deleteRecipe,
                { notify: false },
                data
            );
        };
    }

    static archiveRecipe(recipeId, effectedOrgIds) {
        return async (dispatch, getState) => {
            const data = {
                recipe_id: recipeId,
                effected_org_ids: effectedOrgIds,
                invoker_id: getState().auth.user_id,
                api_token: getState().auth.api_token,
            };
            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_RECIPE_ARCHIVE,
                RecipesEffect.archiveRecipe,
                { notify: false },
                data
            );
        };
    }

    static updateClientSuppliedStatus(recipeId, data) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                RecipesAction.REQUEST_UPDATE_CLIENT_SUPPLIED_STATUS,
                RecipesEffect.updateClientSuppliedStatus(data),
                { notify: true, title: 'Updating Client Supplied Status' },
                data
            );
        };
    }

    static _sortRecords(data) {
        return data.slice().map((list) => {
            return {
                ...list,
                records: list.records.slice().sort((a, b) => parseInt(a.position) - parseInt(b.position)),
            };
        });
    }
}
