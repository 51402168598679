import { BaseModel } from 'sjs-base-model';

export default class IRecipeSnippet extends BaseModel {
    recipe = {
        recipe_id: '',
        organization_id: '',
        title: '',
        state: '',
    };

    /*
     * Client-Side properties (Not from API)
     */

    // noneApiProperties = null;

    constructor(data: any) {
        super();

        this.update(data);
    }
}
