import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import FetchBuschesRecipeWpModel from './models/FetchBuschesRecipeWpModel';

export default class BuschesEffect {
    static async fetchBuschesWpRecipe(data) {
        const endpoint = environment.buschesProjection.exportRecipe.replace(':id', data.recipe_id);

        return EffectUtility.getToModel(FetchBuschesRecipeWpModel, endpoint);
    }
}
