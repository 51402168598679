import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        "success": "success",
        "users": [
            {
                "id": "",
                "organization_id": "",
                "first_name": "",
                "last_name": ""
            }
        ]
    }
 */

export default class UserModel extends BaseModel {
    users = [
        {
            id: '',
            organization_id: '',
            first_name: '',
            last_name: '',
            username: '',
        },
    ];

    /*
     * Client-Side properties (Not from API)
     */

    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
