import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NavbarDesktopView from './desktop/NavbarDesktopView';
import NavbarMobile from './mobile/NavbarMobileView';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Navbar extends Component {
    render() {
        const { width } = this.props;

        const navbarData = [
            {
                key: 0,
                text: 'All Recipes',
                path: '/recipes',
            },
            {
                key: 1,
                text: 'Recipe Tracker',
                path: '/recipe-tracker',
            },
        ];

        return (
            <React.Fragment>
                {width > 800 && <NavbarDesktopView navbarData={navbarData} />}
                {width < 800 && <NavbarDesktopView navbarData={navbarData} />}
            </React.Fragment>
        );
    }
}

Navbar.propTypes = {};

const mapStateToProps = (state) => ({
    width: state.app.width,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
