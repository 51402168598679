import { BaseModel } from 'sjs-base-model';

export default class RecipeSnippet extends BaseModel {
    recipe_id = '';
    title = '';
    organization_id = '';

    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
