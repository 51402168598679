import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import RecipeSnippet from './models/RecipeSnippet';
import CreateRecipeResponse from './models/CreateRecipeResponse';

type ISearchData = {
    page: string;
    order: string;
    count: string;
    field: string;
    term: string;
    state: string;
    organization: string;
};

type ICreateRecipeData = {
    invoker_id: string;
    title: string;
    api_token: string;
    mock_organization_id: string;
};

export default class NewRecipeEffect {
    static async titleSearch(data: ISearchData): Promise<RecipeSnippet | HttpErrorResponseModel> {
        const endpoint = environment.api.recipeSearch.replace(':endpoint', 'dynamic');

        return EffectUtility.getToModel(RecipeSnippet, endpoint);
    }

    static async createRecipe(data: ICreateRecipeData): Promise<CreateRecipeResponse | HttpErrorResponseModel> {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'register');

        return EffectUtility.postToModel(CreateRecipeResponse, endpoint, data);
    }
}
