import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        "success": "success",
    }
 */

export default class INotification extends BaseModel {
    success = '';
    event_type = '';
    message = '';

    /*
     * Client-Side properties (Not from API)
     */

    // noneApiProperties = null;

    // @ts-ignore
    constructor(data) {
        super();

        this.update(data);
    }
}
