import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        "success": "success",
        "recipe": {
            "recipe_id": "1a78bb679a8bae1b48937e50994f6b79",
            "title": "Pot Pie",
            "alt_title": "testing",
            "servings": "[{\"records\": [{\"prefix\": \"Serves\", \"suffix\": \"people\", \"quantity\": \"4\"}, {\"prefix\": \"Serves\", \"suffix\": \"people\", \"quantity\": \"4\"}], \"list_name\": \"\"}, {\"records\": [{\"prefix\": \"Cooks\", \"suffix\": \"\", \"quantity\": \"4\"}], \"list_name\": \"\"}]",
            "prep_info": "[{\"list_name\": \"part 1\", \"prep_info_records\": [{\"type\": \"Prep\", \"unit\": \"minutes\", \"suffix\": \"\", \"quantity\": \"25\"}, {\"type\": \"Bake\", \"unit\": \"hours\", \"suffix\": \"\", \"quantity\": \"15\"}, {\"type\": \"Chill\", \"unit\": \"minutes\", \"suffix\": \"\", \"quantity\": \"20\"}]}, {\"list_name\": \"part 2\", \"prep_info_records\": [{\"type\": \"Prep\", \"unit\": \"minutes\", \"suffix\": \"\", \"quantity\": \"25\"}, {\"type\": \"Bake\", \"unit\": \"hours\", \"suffix\": \"\", \"quantity\": \"15\"}, {\"type\": \"Chill\", \"unit\": \"minutes\", \"suffix\": \"\", \"quantity\": \"20\"}]}]",
            "ingredients": "[{\"list_name\": \"ingredient list 1\", \"ingredient_records\": [{\"unit\": \"\", \"quantity\": \"1\", \"ingredient\": \"ingredient1\"}, {\"unit\": \"lb\", \"quantity\": \"2\", \"ingredient\": \"ingredient2\"}, {\"unit\": \"cups\", \"quantity\": \"3\", \"ingredient\": \"ingredient3\"}]}, {\"list_name\": \"ingredient list 2\", \"ingredient_records\": [{\"unit\": \"teaspoon\", \"quantity\": \"4\", \"ingredient\": \"ingredient4\"}, {\"unit\": \"teaspoon\", \"quantity\": \"5\", \"ingredient\": \"ingredient5\"}, {\"unit\": \"teaspoon\", \"quantity\": \"6\", \"ingredient\": \"ingredient6\"}]}]",
            "directions": "[{\"steps\": [\"This is step 1\", \"This is step 2\"], \"section\": \"Section 1\"}, {\"steps\": [\"This is step 1\", \"This is step 2\"], \"section\": \"\"}]",
            "nutrients": "[{\"list_name\": \"list 1\", \"serving_size\": \"3 pieces\", \"nutrient_records\": [{\"unit\": \"g\", \"nutrient\": \"calories\", \"quantity\": \"1\"}, {\"unit\": \"g\", \"nutrient\": \"calories\", \"quantity\": \"1\"}, {\"unit\": \"g\", \"nutrient\": \"nutrient 2\", \"quantity\": \"1\"}, {\"unit\": \"g\", \"nutrient\": \"protein\", \"quantity\": \"2\"}, {\"unit\": \"mg\", \"nutrient\": \"cholesterol\", \"quantity\": \"3\"}, {\"unit\": \"mg\", \"nutrient\": \"added sugar\", \"quantity\": \"4\"}]}, {\"list_name\": \"list 2\", \"serving_size\": \"3 pieces\", \"nutrient_records\": [{\"unit\": \"g\", \"nutrient\": \"calories\", \"quantity\": \"5\"}, {\"unit\": \"g\", \"nutrient\": \"protein\", \"quantity\": \"6\"}, {\"unit\": \"mg\", \"nutrient\": \"cholesterol\", \"quantity\": \"7\"}, {\"unit\": \"mg\", \"nutrient\": \"added sugar\", \"quantity\": \"8\"}]}]",
            "chef_tips": "[{\"records\": [\"This is step 1\", \"This is step 2\"], \"list_name\": \"list 1\"}, {\"records\": [\"This is step 1\", \"This is step 2\"], \"list_name\": \"list 2\"}]",
            "keywords": "[\"Keyword 1\", \"Keyword 2\"]"
        }
    }
 */

export default class Recipe extends BaseModel {
    recipe = {
        recipe_id: '',
        title: '',
        alt_title: '',
        description: '',
        servings: [],
        prep_info: '',
        ingredients: '',
        directions: '',
        nutrients: '',
        chef_tips: '',
        keywords: '',
        image_names: '',
        state: '',
    };

    /*
     * Client-Side properties (Not from API)
     */

    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
