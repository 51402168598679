import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        "success": "success",
        "path": "https://vimax-content-pro-development-images.s3.us-east-2.amazonaws.com/temp/text_preview_20210301_134255.zip"
    }
 */

export default class AddImageToRecipeResponse extends BaseModel {
    message = '';
    success = false;

    /*
     * Client-Side properties (Not from API)
     */

    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
