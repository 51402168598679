import BaseReducer from '../../utilities/BaseReducer';
import IAction from '../../models/IAction';
import INewRecipeState from './models/INewRecipeState';
import NewRecipeAction from './NewRecipeAction';

export default class NewRecipeReducer extends BaseReducer<INewRecipeState> {
    initialState: INewRecipeState = {
        id: '',
        redirect: false,
    };

    [NewRecipeAction.REQUEST_TITLE_SEARCH_FINISHED](state: INewRecipeState, action: IAction<any>) {
        return {
            ...state,
            tolerance: action.payload,
        };
    }

    [NewRecipeAction.REQUEST_CREATE_RECIPE_FINISHED](state: INewRecipeState, action: IAction<any>) {
        const data = action.payload;

        return {
            ...state,
            id: data.recipe_id,
            redirect: true,
        };
    }
}
