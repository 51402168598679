import ActionUtility from '../../utilities/ActionUtility';
import RecipeTrackerEffect from './RecipeTrackerEffect';

export default class RecipeTrackerAction {
    static UPDATE_RECIPE_STATE = 'RecipeTrackerAction.UPDATE_RECIPE_STATE';

    static updateRecipeState(recipeId, state, orgId) {
        return async (dispatch, getState) => {
            const requestData = {
                recipe_id: recipeId,
                state: state,
                invoker_id: getState().auth.user_id,
                mock_organization_id: orgId,
                api_token: getState().auth.api_token,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                RecipeTrackerAction.UPDATE_RECIPE_STATE,
                RecipeTrackerEffect.updateRecipeState,
                { notify: false },
                requestData
            );
        };
    }
}
