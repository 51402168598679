import React, { Component } from 'react';
import { connect } from 'react-redux';
import StartupAction from '../../../stores/startup/StartupAction';

class Startup extends Component {
    constructor(props) {
        super(props);
        this.updateWindowSize = this.updateWindowSize.bind(this);
    }

    componentDidMount() {
        this.updateWindowSize();
        window.addEventListener('resize', this.updateWindowSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowSize);
    }

    updateWindowSize() {
        const dimensions = {
            width: window.innerWidth,
            height: window.innerHeight,
        };

        this.props.dispatch(StartupAction.updateWindowSize(dimensions));
    }

    render() {
        return <React.Fragment>{this.props.children}</React.Fragment>;
    }
}

export default connect(null)(Startup);
