import ActionUtility from '../../utilities/ActionUtility';
import { ReduxDispatch } from '../../models/ReduxProps';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import RecipeSnippet from './models/RecipeSnippet';
import NewRecipeEffect from './NewRecipeEffect';
import RecipeSearchEffect from '../recipe-search/RecipeSearchEffect';

type ActionUnion = undefined | HttpErrorResponseModel | RecipeSnippet[];

type ISearchData = {
    page: string;
    order: string;
    count: string;
    field: string;
    term: string;
    state: string;
    organization: string;
};

type ICreateRecipeData = {
    invoker_id: string;
    title: string;
    api_token: string;
    mock_organization_id: string;
};

export default class NewRecipeAction {
    static REQUEST_TITLE_SEARCH = 'NewRecipeAction.REQUEST_TITLE_SEARCH';
    static REQUEST_TITLE_SEARCH_FINISHED = 'NewRecipeAction.REQUEST_TITLE_SEARCH_FINISHED';

    static REQUEST_CREATE_RECIPE = 'NewRecipeAction.REQUEST_CREATE_RECIPE';
    static REQUEST_CREATE_RECIPE_FINISHED = 'NewRecipeAction.REQUEST_CREATE_RECIPE_FINISHED';

    static titleSearch(data: ISearchData) {
        return async (dispatch: ReduxDispatch<ActionUnion>) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                NewRecipeAction.REQUEST_TITLE_SEARCH,
                NewRecipeEffect.titleSearch,
                { notify: false },
                data
            );
        };
    }

    static createRecipe(data: ICreateRecipeData) {
        return async (dispatch: ReduxDispatch<ActionUnion>) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                NewRecipeAction.REQUEST_CREATE_RECIPE,
                NewRecipeEffect.createRecipe,
                { notify: false },
                data
            );
        };
    }
}
