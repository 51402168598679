import ActionUtility from '../../utilities/ActionUtility';
import OrganizationsEffect from './OrganizationsEffect';

export default class OrganizationsAction {
    static REQUEST_ORGANIZATIONS = 'OrganizationsAction.REQUEST_PREPARED_FOODS';
    static REQUEST_ORGANIZATIONS_FINISHED = 'OrganizationsAction.REQUEST_PREPARED_FOODS_FINISHED';

    static requestOrganizations() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, OrganizationsAction.REQUEST_ORGANIZATIONS, OrganizationsEffect.requestOrganizations, {
                notify: false,
            });
        };
    }
}
