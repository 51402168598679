import BuschesAction from './BuschesAction';
import BaseReducer from '../../utilities/BaseReducer';

export default class BuschesReducer extends BaseReducer {
    initialState = {
        wp_recipe: '',
    };

    [BuschesAction.FETCH_BUSCHES_WP_FINISHED](state, action) {
        return {
            ...state,
            wp_recipe: action.payload.recipe,
        };
    }
}
