import { BaseModel } from 'sjs-base-model';

export default class IRequestCollectionResponse extends BaseModel {
    collection = {
        id: '',
        affiliated_vendor: '',
        name: '',
        link: '',
        lists: [],
        recipes: [
            {
                recipe_id: '',
                organization_id: '',
                list_id: '',
            },
        ],
    };

    /*
     * Client-Side properties (Not from API)
     */

    // noneApiProperties = null;

    constructor(data: any) {
        super();

        this.update(data);
    }
}
