import React, { Component } from 'react';
import AuthView from './AuthView';
import AuthAction from '../../../stores/auth/AuthAction';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class AuthContainer extends Component {
    constructor(props) {
        super(props);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkForCachedToken = this.checkForCachedToken.bind(this);
        this.requestTokenValidation = this.requestTokenValidation.bind(this);
    }

    componentDidMount() {
        this.checkForCachedToken();
    }

    checkForCachedToken() {
        const api_token = localStorage.getItem('api_token');

        if (api_token) {
            this.requestTokenValidation(api_token);
        }
    }

    requestTokenValidation(api_token) {
        const data = {
            api_token: api_token,
        };

        this.props.dispatch(AuthAction.requestTokenValidation(data));
    }

    handleFormChange(event) {
        const data = {
            key: event.target.name,
            value: event.target.value,
        };

        this.props.dispatch(AuthAction.handleFormChange(data));
    }

    handleSubmit(event) {
        event.preventDefault();

        const data = {
            email: this.props.auth.email,
            password: this.props.auth.password,
        };

        this.props.dispatch(AuthAction.requestUserAuthentication(data));
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/recipes' } };

        if (this.props.auth.is_authenticated === true) {
            localStorage.setItem('api_token', this.props.auth.api_token);
            return <Redirect to={from} />;
        }
        return <AuthView handleFormChange={this.handleFormChange} handleSubmit={this.handleSubmit} />;
    }
}

AuthContainer.propTypes = {};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, null)(AuthContainer);
