import ActionUtility from '../../utilities/ActionUtility';
import UserEffect from './UserEffect';

export default class UserAction {
    static REQUEST_USERS = 'UserAction.REQUEST_USERS';
    static REQUEST_USERS_FINISHED = 'UserAction.REQUEST_USERS_FINISHED';

    static requestUsers(data) {
        return async (dispatch, getState) => {
            data = {
                ...data,
                api_token: getState().auth.api_token,
            };

            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_USERS, UserEffect.requestUsers, { notify: false }, data);
        };
    }
}
