import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import ActionUtility from '../../utilities/ActionUtility';

type ActionUnion = undefined | HttpErrorResponseModel;

export default class NotificationAction {
    static CLOSE_NOTIFICATION = 'NotificationAction.CLOSE_NOTIFICATION';

    static closeNotification(id: string) {
        return ActionUtility.createAction(NotificationAction.CLOSE_NOTIFICATION, id);
    }
}
