import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import Recipe from './models/Recipe';
import UpdateRecipeResponse from './models/UpdateRecipeResponse';
import ExportSalesTeamResponse from './models/ExportSalesTeamResponse';
import StateLog from './models/StateLog';
import INotification from '../notifications/models/INotification';
import RecipeClients from './models/RecipeClients';
import axios from "axios";

export default class RecipesEffect {
    static async requestSingleRecipe(data) {
        const endpoint = environment.api.recipeSearch.replace(':endpoint', 'recipe');

        return EffectUtility.getToModel(Recipe, endpoint, data);
    }

    static async requestRecipeClients(data) {
        const endpoint = environment.api.recipeSearch.replace(':endpoint', 'clients');

        return EffectUtility.getToModel(RecipeClients, endpoint, data);
    }

    static async requestStateLog(data) {
        const endpoint = environment.api.stateLogs;

        return EffectUtility.getToModel(StateLog, endpoint, data);
    }

    static async updateTitle(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'update-title');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static async updateDescription(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'update-description');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static updateServings(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'update-servings');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static async updatePrepInfo(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'update-prep-info');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static async updateIngredients(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'update-ingredients');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static async updateDirections(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'update-directions');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static async updateNutrients(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'update-nutrients');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static async updateTips(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'update-chef-tips');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static async updateKeywords(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'update-keywords');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static async updateImageNames(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'update-image-names');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static async exportPDF(data) {
        const endpoint = environment.api.recipeSearch.replace(':endpoint', 'export/pdf');

        return EffectUtility.postToModel(INotification, endpoint, { recipes: data });
    }

    static async exportSalesTeam(data) {
        const endpoint = environment.api.recipeSearch.replace(':endpoint', 'export/sales');

        const recipes = {
            recipes: [data],
        };

        return EffectUtility.postToModel(ExportSalesTeamResponse, endpoint, recipes);
    }

    static async deleteRecipe(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'delete');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static async archiveRecipe(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'archive');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static async updateClientSuppliedStatus(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'update-client-supplied-status');

        return EffectUtility.postToModel(UpdateRecipeResponse, endpoint, data);
    }

    static async unlinkRunsWith(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'destroy-runs-with');

        return await axios.post(endpoint, data);
    }

    static async linkRunsWith(data) {
        const endpoint = environment.api.update_recipe.replace(':attribute', 'create-runs-with');

        return await axios.post(endpoint, data);
    }
}
