import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import RequestingReducer from './requesting/RequestingReducer';
import ErrorReducer from './error/ErrorReducer';
import StartupReducer from './startup/StartupReducer';
import AuthReducer from './auth/AuthReducer';
import RecipesReducer from './recipes/RecipesReducer';
import RecipeSearchReducer from './recipe-search/RecipeSearchReducer';
import ImageGalleryReducer from './image-gallery/ImageGalleryReducer';
import OrganizationsReducer from './organizations/OrganizationsReducer';
import BuschesReducer from './busches/BuschesReducer';
import RecipeTrackerReducer from './recipe-tracker/RecipeTrackerReducer';
import ExportReducer from './export/ExportReducer';
import UserReducer from './users/UserReducer';
import NewRecipeReducer from './new-recipe/NewRecipeReducer';
import TertiaryCollectionReducer from './tertiary-collection/TertiaryCollectionReducer';
import NotificationReducer from './notifications/NotificationReducer';

export default (history) => {
    const reducerMap = {
        error: ErrorReducer.reducer,
        notifications: NotificationReducer.reducer,
        app: new StartupReducer().reducer,
        requesting: RequestingReducer.reducer,
        router: connectRouter(history),
        auth: new AuthReducer().reducer,
        recipe_search: new RecipeSearchReducer().reducer,
        recipe: new RecipesReducer().reducer,
        image_gallery: new ImageGalleryReducer().reducer,
        organizations: new OrganizationsReducer().reducer,
        busches: new BuschesReducer().reducer,
        recipe_tracker: new RecipeTrackerReducer().reducer,
        export: new ExportReducer().reducer,
        users: new UserReducer().reducer,
        newRecipe: new NewRecipeReducer().reducer,
        tertiaryCollections: new TertiaryCollectionReducer().reducer,
    };

    return combineReducers(reducerMap);
};
