import BaseReducer from '../../utilities/BaseReducer';
import IAction from '../../models/IAction';
import ICollectionState from './models/ICollectionState';
import TertiaryCollectionAction from './TertiaryCollectionAction';
import { v4 as uuidv4 } from 'uuid';
import IRequestAllResponse from './models/IRequestAllResponse';
import IRequestCollectionResponse from './models/IRequestCollectionResponse';

export default class TertiaryCollectionReducer extends BaseReducer<ICollectionState> {
    initialState: ICollectionState = {
        search: {
            name: '',
            page: 1,
            count: 50,
            affiliatedVendor: '',
        },
        open: false,
        sidebar: {
            view: 'new',
        },
        active: {
            name: '',
            link: false,
            affiliatedVendor: '',
            lists: [
                {
                    title: '',
                    id: uuidv4(),
                },
            ],
            recipes: [],
        },
        collections: [],
    };

    [TertiaryCollectionAction.TOGGLE_OPEN](state: ICollectionState, action: IAction<any>) {
        return {
            ...state,
            open: action.payload,
        };
    }

    [TertiaryCollectionAction.TOGGLE_RECIPE](state: ICollectionState, action: IAction<any>) {
        const payload = action.payload;
        const exists =
            state.active.recipes.filter((recipe) => recipe.recipeId === payload.recipeId && recipe.organizationId === payload.orgId).length > 0;

        let recipes = state.active.recipes.slice();

        if (!exists) {
            recipes.push({
                requested: true,
                title: payload.title,
                recipeId: payload.recipeId,
                organizationId: payload.orgId,
                organizationName: payload.orgName,
                listId: state.active.lists[0].id,
                position: 0,
                id: uuidv4(),
                state: payload.state,
            });
        } else {
            recipes = recipes.filter((recipe) => recipe.recipeId !== payload.recipeId || recipe.organizationId !== payload.orgId);
        }

        return {
            ...state,
            active: {
                ...state.active,
                recipes: recipes,
            },
        };
    }

    [TertiaryCollectionAction.TOGGLE_LIST](state: ICollectionState, action: IAction<any>) {
        const payload = action.payload;

        let lists = state.active.lists.slice();
        let recipes = state.active.recipes.slice();

        if (payload.id.length < 1) {
            lists.push({ title: '', id: uuidv4() });
        } else {
            recipes = recipes.filter((recipe) => payload.id !== recipe.listId);
            lists = lists.filter((list) => list.id !== payload.id);
        }

        return {
            ...state,
            active: {
                ...state.active,
                lists: lists,
                recipes: recipes,
            },
        };
    }

    [TertiaryCollectionAction.UPDATE_RECIPE](state: ICollectionState, action: IAction<any>) {
        const payload = action.payload;

        return {
            ...state,
            active: {
                ...state.active,
                recipes: state.active.recipes.map((recipe) => {
                    if (recipe.id === payload.id) {
                        return {
                            ...recipe,
                            [payload.key]: payload.value,
                        };
                    } else {
                        return recipe;
                    }
                }),
            },
        };
    }

    [TertiaryCollectionAction.UPDATE_LIST](state: ICollectionState, action: IAction<any>) {
        const payload = action.payload;

        return {
            ...state,
            active: {
                ...state.active,
                lists: state.active.lists.map((list) => {
                    if (list.id === payload.id) {
                        return {
                            ...list,
                            [payload.key]: payload.value,
                        };
                    } else {
                        return list;
                    }
                }),
            },
        };
    }

    [TertiaryCollectionAction.UPDATE_COLLECTION](state: ICollectionState, action: IAction<any>) {
        const payload = action.payload;

        return {
            ...state,
            active: {
                ...state.active,
                [payload.key]: payload.value,
            },
        };
    }

    [TertiaryCollectionAction.REQUEST_COLLECTION_FINISHED](state: ICollectionState, action: IAction<any>) {
        const payload = action.payload.collection;

        return {
            ...state,
            active: {
                id: payload.id,
                name: payload.name,
                affiliatedVendor: payload.affiliated_vendor,
                link: payload.link,
                lists: JSON.parse(payload.lists),
                recipes: JSON.parse(payload.recipes).map((recipe: IRequestCollectionResponse['collection']['recipes'][0]) => {
                    return {
                        id: uuidv4(),
                        requested: false,
                        recipeId: recipe.recipe_id,
                        organizationId: recipe.organization_id,
                        listId: recipe.list_id,
                    };
                }),
            },
        };
    }

    [TertiaryCollectionAction.REQUEST_SEARCH_COLLECTIONS_FINISHED](state: ICollectionState, action: IAction<any>) {
        const payload = action.payload;

        return {
            ...state,
            collections: payload.collections.map((a: IRequestAllResponse['collections'][0]) => {
                return {
                    id: a.id,
                    affiliatedVendor: a.affiliated_vendor,
                    name: a.name,
                    count: a.recipe_count,
                    position: 0,
                };
            }),
        };
    }

    [TertiaryCollectionAction.REQUEST_ADDITIONAL_RECIPE_INFO](state: ICollectionState, action: IAction<any>) {
        const payload = action.payload[0];

        return {
            ...state,
            active: {
                ...state.active,
                recipes: state.active.recipes.slice().map((recipe) => {
                    if (payload.recipe_id === recipe.recipeId && payload.organization_id === recipe.organizationId) {
                        return {
                            ...recipe,
                            requested: true,
                        };
                    } else {
                        return recipe;
                    }
                }),
            },
        };
    }

    [TertiaryCollectionAction.REQUEST_ADDITIONAL_RECIPE_INFO_FINISHED](state: ICollectionState, action: IAction<any>) {
        const payload = action.payload.recipe;

        return {
            ...state,
            active: {
                ...state.active,
                recipes: state.active.recipes.slice().map((recipe) => {
                    if (payload.recipe_id === recipe.recipeId && payload.organization_id === recipe.organizationId) {
                        return {
                            ...recipe,
                            title: payload.title,
                            state: payload.state,
                        };
                    } else {
                        return recipe;
                    }
                }),
            },
        };
    }

    [TertiaryCollectionAction.CLEAR](state: ICollectionState, action: IAction<any>) {
        return {
            ...state,
            active: {
                name: '',
                link: false,
                affiliatedVendor: '',
                lists: [
                    {
                        title: '',
                        id: uuidv4(),
                    },
                ],
                recipes: [],
            },
        };
    }

    [TertiaryCollectionAction.UPDATE_SEARCH](state: ICollectionState, action: IAction<any>) {
        const data = action.payload;
        return {
            ...state,
            search: {
                ...state.search,
                [data.key]: data.value,
            },
        };
    }

    [TertiaryCollectionAction.UPDATE_SIDEBAR](state: ICollectionState, action: IAction<any>) {
        const data = action.payload;
        return {
            ...state,
            sidebar: {
                ...state.sidebar,
                [data.key]: data.value,
            },
        };
    }
}
