import { BaseModel } from 'sjs-base-model';

export default class IRequestCollectionResponse extends BaseModel {
    success = '';

    /*
     * Client-Side properties (Not from API)
     */

    // noneApiProperties = null;

    constructor(data: any) {
        super();

        this.update(data);
    }
}
