import BaseReducer from '../../utilities/BaseReducer';
import ExportAction from './ExportAction';

export default class exportReducer extends BaseReducer {
    initialState = {
        text_file_path: '',
    };

    [ExportAction.REQUEST_EXPORT_TO_TEXT_FINISHED](state, action) {
        const data = action.payload;

        return {
            ...state,
            text_file_path: data.path,
        };
    }

    [ExportAction.REQUEST_EXPORT_TO_DOLE_FINISHED](state, action) {
        const data = action.payload;

        return {
            ...state,
            text_file_path: data.path,
        };
    }
}
