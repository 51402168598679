import ActionUtility from '../../utilities/ActionUtility';
import BuschesEffect from './BuschesEffect';

export default class BuschesAction {
    static FETCH_BUSCHES_WP = 'BuschesAction.FETCH_BUSCHES_WP';
    static FETCH_BUSCHES_WP_FINISHED = 'BuschesAction.FETCH_BUSCHES_WP_FINISHED';

    static fetchBuschesWpRecipe(id) {
        return async (dispatch) => {
            const data = {
                recipe_id: id,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                BuschesAction.FETCH_BUSCHES_WP,
                BuschesEffect.fetchBuschesWpRecipe,
                { notify: false },
                data
            );
        };
    }
}
