import INotificationState from './models/INotificationState';
import NotificationAction from './NotificationAction';

function date(): string {
    const date = new Date(); // 2009-11-10
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    const time = date.toLocaleTimeString();
    return month + '. ' + day + ', ' + year + ' - ' + time;
}

/*
 * Note: This reducer breaks convention on how reducers should be setup.
 */
export default class NotificationReducer {
    static initialState: INotificationState = {
        records: [],
    };

    static reducer(state = NotificationReducer.initialState, action: { type: string; error: boolean; payload: any; meta: any }) {
        const { type, error, payload, meta } = action;

        // console.log(type);
        // console.log(error);
        // console.log(payload);
        /*
         * Removes an HttpErrorResponseModel by it's id that is in the action payload.
         */
        if (type === NotificationAction.CLOSE_NOTIFICATION) {
            return {
                ...state,
                records: state.records.filter((record) => record.id !== payload),
            };
        }

        /*
         * Removes all errors by returning the initial state which is an empty object.
         */
        // if (type === ErrorAction.CLEAR_ALL) {
        //     return ErrorReducer.initialState;
        // }

        /*
         * True if the action type has the key word '_FINISHED' then the action is finished.
         */
        const isFinishedRequestType = type.includes('_FINISHED');
        /*
         * True if the action type has the key word 'REQUEST_' and not '_FINISHED'.
         */
        const isStartRequestType = type.includes('REQUEST_') && !isFinishedRequestType;

        /*
         * If an action is started we want to remove any old errors because there is a new action has been re-dispatched.
         */
        // if (isStartRequestType) {
        //     // Using ES7 Object Rest Spread operator to omit properties from an object.
        //     // @ts-ignore
        //     const { [`${type}_FINISHED`]: value, ...stateWithoutFinishedType } = state;
        //
        //     return stateWithoutFinishedType;
        // }

        if (isStartRequestType) {
            if (!payload[0]) return state;

            if (!meta.notify) return state;

            console.log(meta.title);
            const records = state.records.slice();
            records.push({
                success: false,
                loading: true,
                errors: [],
                id: meta.request_id,
                title: meta.title ? meta.title : type,
                message: '',
                datetime: date(),
            });

            return {
                ...state,
                records: records,
            };
        }

        if (isFinishedRequestType) {
            const records = state.records.slice();
            const updated = records.map((record) => {
                if (record.id === meta.request_id) {
                    return {
                        ...record,
                        loading: false,
                        errors: payload.errors ? payload.errors : [],
                        success: payload.success,
                        message: payload.message,
                        datetime: date(),
                    };
                } else {
                    return record;
                }
            });

            return {
                ...state,
                records: updated,
            };
        }

        /*
         * True if the action is finished and the error property is true.
         */
        const isError = isFinishedRequestType && Boolean(error);

        /*
         * For any start and finished actions that don't have errors we return the current state.
         */
        if (!isError) {
            return state;
        }

        /*
         * At this point the "type" will be a finished action type (e.g. "SomeAction.REQUEST_*_FINISHED").
         * The payload will be a HttpErrorResponseModel.
         */
        return {
            ...state,
            // [type]: payload,
        };
    }
}
