import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        "success": "success",
        "recipes": [
            {
                "title": "Pot Pie",
                "recipe_id": "1a78bb679a8bae1b48937e50994f6b79"
            }
        ]
    }
 */

export default class RecipeSnippet extends BaseModel {
    recipes = [
        {
            title: '',
            recipe_id: '',
            organization_id: '',
            state: '',
        },
    ];

    /*
     * Client-Side properties (Not from API)
     */

    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
