import HttpErrorResponseModel from '../models/HttpErrorResponseModel';
import { v4 as uuidv4 } from 'uuid';

export default class ActionUtility {
    static async createThunkEffect(dispatch: any, actionType: any, effect: any, meta: { notify: boolean; title?: string }, ...args: any) {
        const requestId = uuidv4();

        dispatch(ActionUtility.createAction(actionType, args, false, { request_id: requestId, notify: meta.notify, title: meta.title }));

        const model = await effect(...args);
        const isError = model instanceof HttpErrorResponseModel;

        dispatch(
            ActionUtility.createAction(`${actionType}_FINISHED`, model, isError, { request_id: requestId, notify: meta.notify, title: meta.title })
        );

        return model;
    }

    static createAction(type: string, payload: any, error = false, meta: any = null) {
        return { type, payload, error, meta };
    }
}
