import React, {useEffect, useMemo, useRef, useState} from 'react';
import WhiteExit from '../../../images/general/white-exit.svg';
import gsap from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

type IProps = {
    notifications: INotificationProps[];
    closeNotification: (id: string) => void;
};

function urlify(text: string): any {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
}

const NotificationView = (props: IProps) => {
    return (
        <div className={'fixed top-20 right-0 z-20'}>
            {props.notifications.map((notification, i) => {
                return (
                    <Notification
                        key={notification.id}
                        id={notification.id}
                        title={notification.title}
                        closeNotification={props.closeNotification}
                        message={notification.message}
                        datetime={notification.datetime}
                        loading={notification.loading}
                        success={notification.success}
                        errors={notification.errors}
                        timeout={notification.timeout}
                        requestComplete={notification.requestComplete}
                    />
                );
            })}
        </div>
    );
};

export default NotificationView;

export type INotificationProps = {
    id: string;
    title: string;
    loading: boolean;
    errors?: string[];
    success: boolean;
    message?: string;
    datetime: string;
    requestComplete?: boolean;
    closeNotification?: (id: string) => void;
    timeout?: number;
};

const Notification = (props: INotificationProps) => {
    gsap.registerPlugin(DrawSVGPlugin);

    const notificationRef = useRef<HTMLDivElement | null>(null);
    const circleRef = useRef<any>(null);
    const tl = useMemo(() => gsap.timeline({ repeat: -1 }), []);
    const [timerSet, setTimer] = useState<boolean>(false);

    function checkShouldStop(success: boolean, loading: boolean) {
        if (success) {
            tl.clear();
            tl.to(circleRef.current, {
                drawSVG: '100%',
                stroke: '#50C878',
            }).repeat(0);
        }

        if (!loading && !success) {
            tl.clear();
            tl.to(circleRef.current, {
                drawSVG: '100%',
                stroke: 'red',
            }).repeat(0);
        }
    }
    checkShouldStop(props.success, props.loading);

    useEffect(() => {
        gsap.fromTo(
            notificationRef.current,
            {
                opacity: 0,
                x: '100%',
            },
            {
                opacity: 1,
                x: 0,
            }
        );
    }, []);

    useEffect(() => {
        tl.fromTo(
            circleRef.current,
            { drawSVG: '0%' },
            {
                drawSVG: '100%',
                immediateRender: false,
                ease: 'Power1.easeInOut',
                rotation: 360,
                transformOrigin: '50% 50%',
                stroke: '#29B6F6',
                duration: 3,
            }
        ).fromTo(
            circleRef.current,
            { drawSVG: '100%' },
            {
                drawSVG: '0%',
                immediateRender: false,
                ease: 'Power1.easeInOut',
                rotation: -720,
                transformOrigin: '50% 50%',
                stroke: '#FF4081',
                duration: 1.5,
            }
        );
    }, []);

    useEffect(() => {
        if(props.requestComplete && props.timeout && !timerSet) {
            setTimer(true);
            setTimeout(() => {
                if (props.closeNotification) {
                    props.closeNotification(props.id);
                }
            }, props.timeout);
        }
    }, [props.requestComplete])

    return (
        <div ref={notificationRef} className={'w-96 bg-gray-600 white p-3 flex-col mb-3 relative opacity-0'}>
            <button onClick={() => props.closeNotification ? props.closeNotification(props.id) : null} className={'absolute top-3 right-3'}>
                <img src={WhiteExit} alt={'exit popup'} className={'w-5 h-5 cursor-pointer'} />
            </button>

            <h1 className={'white mb-5 font-bold border-b border-white pb-1 w-max'}>{props.title}</h1>

            <div className={'flex items-center mb-3'}>
                <svg version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30">
                    <circle ref={circleRef} fill="none" cx="15" cy="15" r="12.5" strokeWidth={2} stroke={props.success ? '#50C878' : '#FFFFFF'} />
                </svg>
                <p className={'ml-3 leading-none'}>
                    {!props.loading && props.success ? 'success' : null}
                    {!props.loading && !props.success ? 'failure' : null}
                    {props.loading ? 'loading' : null}
                </p>
            </div>

            <div className={'flex flex-col'}>
                {props.message ? <p className={'mb-3 text-sm'} dangerouslySetInnerHTML={{ __html: urlify(props.message) }} /> : null}

                {!props.message && props.errors && props.errors?.length > 0
                    ? props.errors.map((error, i) => {

                    return <p key={i} className={'mb-3'}>{error}</p>;
                }) : null}

            </div>

            <label>
                <i className={'text-xs'}>{props.datetime}</i>
            </label>
        </div>
    );
};
