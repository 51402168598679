import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        "success": "success",
        "images": [
            {
                "image_id": "4d4e2b2c07eb8bd9e9948fc6fc63c6d6",
                "path": "https://vimax-content-pro-images.s3.us-east-2.amazonaws.com/4d4e2b2c07eb8bd9e9948fc6fc63c6d6.jpeg",
                "image_size": "600x320"
            }
        ]
    }
 */

export default class Image extends BaseModel {
    success = '';
    images = {
        image_id: '',
        path: '',
        image_size: '',
    };

    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
