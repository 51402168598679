import BaseReducer from '../../utilities/BaseReducer';
import UserAction from './UserAction';

export default class UserReducer extends BaseReducer {
    initialState = {
        users: [],
    };

    [UserAction.REQUEST_USERS_FINISHED](state, action) {
        const data = action.payload;

        return {
            ...state,
            users: data.users,
        };
    }
}
