import HttpUtility from '../../utilities/HttpUtility';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import RequestOrganizationsResponse from './models/RequestOrganizationsResponse';

export default class OrganizationsEffect {
    static async requestOrganizations(preparedFoodId) {
        const endpoint = environment.api.organizations;

        return EffectUtility.getToModel(RequestOrganizationsResponse, endpoint);
    }
}
