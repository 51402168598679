import React, { Component } from 'react';
import NotificationView from './NotificationView';
import { connect } from 'react-redux';
import NotificationAction from '../../../stores/notifications/NotificationAction';

type IState = any;

type IProps = any;

type IStateToProps = {
    notifications: {
        records: {
            success: boolean;
            loading: boolean;
            errors: string[];
            id: string;
            title: string;
            message: string;
            datetime: string;
        }[];
    };
};

class NotificationContainer extends Component<IState, IProps> {
    constructor(props: IProps) {
        super(props);

        this.closeNotification = this.closeNotification.bind(this);
    }

    closeNotification(id: string) {
        this.props.dispatch(NotificationAction.closeNotification(id));
    }

    render() {
        return <NotificationView notifications={this.props.notifications} closeNotification={this.closeNotification} />;
    }
}

const mapStateToProps = (state: IStateToProps) => ({
    notifications: state.notifications.records,
});

export default connect(mapStateToProps, null)(NotificationContainer);
