import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        "success": "success",
        "recipe": "[page_imgtag fname='gallery/recipes/GrilledColaChickenWings.jpg' falt='Grilled Cola Chicken Wings'][page_dltag][email_link]<br /><h1 class='nostyle'><span class='recTitle'>Grilled Cola Chicken Wings</span></h1><br />Prep: 20 minutes Grill: 25 minutes • Serves: 8 \n<div class=\"recIngre\"><span>4</span>chicken wings, patted dry</span>\n<span>2</span>olive oil</span>\n<span>1</span>kosher salt</span>\n<span>1/2</span>ground black pepper</span>\n<span>1</span>can (12 ounces) cola</span>\n<span>2</span>garlic cloves, minced</span>\n<span>1/4</span>packed brown sugar</span>\n<span>1/4</span>tomato paste</span>\n<span>2</span>cayenne hot pepper sauce</span>\n<span>1</span>smoked paprika</span>\n</div>\n\n<span class=\"recPrep\"><span>1. </span>Prepare outdoor grill for direct grilling over low heat. With kitchen scissors, snip off wing tips; discard. With sharp knife, split wings at the joint into the middle section and drumette.</span>\n<span class=\"recPrep\"><span>2. </span>In large bowl, toss wings, oil, salt and black pepper. Place wings on hot grill rack; cover and cook 25 minutes or until internal temperature reaches 165°, turning once.   </span>\n<span class=\"recPrep\"><span>3. </span>In medium saucepan, heat Coca-Cola®, garlic, brown sugar, tomato paste, hot pepper sauce and paprika to a boil over medium-high heat. Reduce heat to low; cook 10 minutes or until sauce thickens.</span>\n<span class=\"recPrep\"><span>4. </span>In large bowl, toss wings and ½ the sauce. Serve with remaining sauce for dipping.  </span>\n\n<span class=\"recNut\"><br />Approximate nutritional values per serving:\n354 Calories, 21 g Fat 6 g Saturated, 150 mg Cholesterol, 504 mg Sodium, 14 g Carbohydrates, 1 g Fiber, 13 g Sugars, 12 g Added Sugars, 26 g ProteinKeywords:\nChicken\nAppetizers\n\n"
    }
 */

export default class FetchBuschesRecipeWpModel extends BaseModel {
    recipe = '';
    success = '';

    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
