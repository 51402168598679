import React, { lazy, Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import RouteEnum from '../constants/RouteEnum';
import LoadingIndicator from './components/loading-indicator/LoadingIndicator';
import Startup from './components/startup';
import Login from './pages/auth';
import PrivateRoute from './components/private-route';
import Navbar from './components/navbar';
import './App.scss';
import Notification from './components/notification';
const NotFoundPage = lazy(() => import('./pages/not-found-page/NotFoundPage'));
const RecipeSearch = lazy(() => import('./pages/recipe-search'));
const RecipeIndividualEdit = lazy(() => import('./pages/recipe-edit'));
const RecipeIndividualView = lazy(() => import('./pages/recipe-preview'));
const TertiaryCollectionSearch = lazy(() => import('./pages/tertiary-collection-search'));
const TertiaryCollection = lazy(() => import('./pages/tertiary-collection'));

export default class App extends React.Component {
    render() {
        return (
            <Startup>
                <ConnectedRouter history={this.props.history}>
                    <Suspense fallback={<LoadingIndicator isActive={true} />}>
                        <Navbar />
                        <Notification />
                        <Switch>
                            <Route exact={true} path={RouteEnum.Login} component={Login} />
                            <PrivateRoute exact={true} path={RouteEnum.Recipes} component={RecipeSearch} />
                            <PrivateRoute exact={true} path={RouteEnum.Home} component={RecipeSearch} />
                            <PrivateRoute exact={true} path={RouteEnum.RecipeEdit} component={RecipeIndividualEdit} />
                            <PrivateRoute exact={true} path={RouteEnum.RecipeView} component={RecipeIndividualView} />
                            <PrivateRoute exact={true} path={RouteEnum.TertiaryCollectionSearch} component={TertiaryCollectionSearch} />
                            <PrivateRoute exact={true} path={RouteEnum.TertiaryCollection} component={TertiaryCollection} />
                            <PrivateRoute component={NotFoundPage} />
                        </Switch>
                    </Suspense>
                </ConnectedRouter>
            </Startup>
        );
    }
}
