import BaseReducer from '../../utilities/BaseReducer';
import OrganizationsAction from './OrganizationsAction';

export default class OrganizationsReducer extends BaseReducer {
    initialState = {
        organizations: [
            {
                name: '',
                id: '',
            },
        ],
    };

    [OrganizationsAction.REQUEST_ORGANIZATIONS_FINISHED](state, action) {
        const data = action.payload;
        return {
            ...state,
            organizations: data.organizations.map((organization) => {
                return {
                    name: organization.organization_name,
                    id: organization.organization_id,
                };
            }),
        };
    }
}
