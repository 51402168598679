import RecipeSearchAction from './RecipeSearchAction';
import BaseReducer from '../../utilities/BaseReducer';

export default class RecipeSearchReducer extends BaseReducer {
    initialState = {
        recipes: [],
        searchCriteria: {
            archived: false,
            page: 1,
            order: 'ASC',
            count: 50,
            term: '',
            field: 'title',
            exact: false,
            state: '',
            organization: '',
        },
        available_states: [],
    };

    [RecipeSearchAction.UPDATE_SEARCH_CRITERIA](state, action) {
        const data = action.payload;

        return {
            ...state,
            searchCriteria: {
                ...state.searchCriteria,
                [data.key]: data.value,
            },
        };
    }

    [RecipeSearchAction.REQUEST_DYNAMIC_SEARCH_FINISHED](state, action) {
        const data = action.payload;

        return {
            ...state,
            recipes: data.recipes.map((recipe) => {
                return {
                    recipe_id: recipe.recipe_id,
                    organization_id: recipe.organization_id,
                    title: recipe.title,
                    state: recipe.state,
                };
            }),
        };
    }

    [RecipeSearchAction.REQUEST_AVAILABLE_STATES_FINISHED](state, action) {
        const data = action.payload;

        return {
            ...state,
            available_states: data.states.map((record) => {
                return record.state;
            }),
        };
    }
}
