import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import ExportResponse from './models/ExportResponse';
import WpExportResponse from './models/WpExportResponse';

export default class ExportEffect {
    static async exportToText(data) {
        const endpoint = environment.api.recipeSearch.replace(':endpoint', 'export/text');

        const request = {
            recipes: data,
        };

        return EffectUtility.postToModel(ExportResponse, endpoint, request);
    }

    static async exportToDole(data) {
        const endpoint = environment.api.dole.replace(':endpoint', 'export');

        const request = {
            recipes: data,
        };

        return EffectUtility.postToModel(ExportResponse, endpoint, request);
    }

    static async exportToWp(data) {
        const endpoint = environment.api.recipeSearch.replace(':endpoint', 'export/wp');

        return EffectUtility.getToModel(WpExportResponse, endpoint, data);
    }
}
