import ImageGalleryAction from './ImageGalleryAction';
import BaseReducer from '../../utilities/BaseReducer';
import RecipesAction from '../recipes/RecipesAction';

export default class ImageGalleryReducer extends BaseReducer {
    initialState = {
        selected_image: '',
        images: [],
        collection_image_ids: [],
    };

    [ImageGalleryAction.CLEAR_IMAGES](state, action) {
        return {
            ...state,
            images: [],
            collection_image_ids: [],
        };
    }

    [ImageGalleryAction.REQUEST_BATCH_IMAGES_FINISHED](state, action) {
        const data = action.payload;

        return {
            ...state,
            images: data.images.map((image) => {
                return {
                    id: image.image_id,
                    path: image.path,
                    size: image.image_size,
                };
            }),
        };
    }

    [ImageGalleryAction.REQUEST_PRIMARY_COLLECTION_FINISHED](state, action) {
        const data = action.payload;

        const imageIds = data.primary_collection.image_ids;

        return {
            ...state,
            collection_image_ids: imageIds.length > 0 ? JSON.parse(imageIds) : [],
        };
    }

    [ImageGalleryAction.SELECT_IMAGE](state, action) {
        return {
            ...state,
        };
    }
}
