import { BaseModel } from 'sjs-base-model';

export default class RecipeClients extends BaseModel {
    recipe_id = '';
    clients = [];

    /*
     * Client-Side properties (Not from API)
     */

    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
