import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        "success": "success",
        "primary_collection": {
            "collection_id": "cc9910a2920751e5493108d63b3d18fa",
            "recipe_id": "26adffec86a4d6b4e79395b490296275",
            "image_ids": "[\"3f05c7efaef7b30ada00cd7894d9750a\",\"a53a4d0e580dac2c8909804484742337\"]"
        }
    }
 */

export default class PrimaryCollection extends BaseModel {
    primary_collection = {
        collection_id: '',
        recipe_id: '',
        image_ids: [],
    };

    /*
     * Client-Side properties (Not from API)
     */

    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
