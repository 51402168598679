enum RouteEnum {
    Login = '/login',
    Home = '/',
    Recipes = '/recipes',
    RecipeView = '/recipe/view',
    RecipeEdit = '/recipe/edit',
    TertiaryCollectionSearch = '/collections',
    TertiaryCollection = '/collection/:id',
}

export default RouteEnum;
