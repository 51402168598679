import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        "success": "success",
        "log": [
            {
                "entry_id": 14,
                "recipe_id": "e1b3cdfe2c83e0ef96c98f0cb99753b4",
                "organization_id": "6054fec14685c893d8abba763b33706e",
                "state": "REQUEST_TEST",
                "created_at": "2021-02-11 13:38:40",
                "invoker_id": "1311f2e1e5de9cf6b9eb0169d3e67b43"
            },
            {
                "entry_id": 16,
                "recipe_id": "e1b3cdfe2c83e0ef96c98f0cb99753b4",
                "organization_id": "6054fec14685c893d8abba763b33706e",
                "state": "TEST_REQUEST_APPROVED",
                "created_at": "2021-02-11 14:27:14",
                "invoker_id": "4ccb8b6c08557010179e84b867364fd2"
            }
        ]
    }
 */

export default class StateLog extends BaseModel {
    log = [
        {
            recipe_id: '',
            organization_id: '',
            state: '',
            created_at: '',
            invoker_id: '',
        },
    ];

    /*
     * Client-Side properties (Not from API)
     */

    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
