import { BaseModel } from 'sjs-base-model';

export default class RecipeStateSnippet extends BaseModel {
    states = [
        {
            state: '',
        },
    ];

    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
