import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        {
            "success": "success"
        }
    }
 */

export default class AddImageToRecipeResponse extends BaseModel {
    success = '';
    image_id = '';

    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
