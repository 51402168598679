import ActionUtility from '../../utilities/ActionUtility';
import ImageGalleryEffect from './ImageGalleryEffect';

export default class ImageGalleryAction {
    static SELECT_IMAGE = 'ImagesAction.SELECT_IMAGE';

    static CLEAR_IMAGES = 'ImagesAction.CLEAR_IMAGES';

    static REQUEST_IMAGE_ADD_TO_RECIPE = 'ImagesAction.REQUEST_IMAGE_ADD_TO_RECIPE';
    static REQUEST_IMAGE_ADD_TO_RECIPE_FINISHED = 'ImagesAction.REQUEST_IMAGE_ADD_TO_RECIPE_FINISHED';

    static REQUEST_IMAGE_DELETION = 'ImagesAction.REQUEST_IMAGE_DELETION';
    static REQUEST_IMAGE_DELETION_FINISHED = 'ImagesAction.REQUEST_IMAGE_DELETION_FINISHED';

    static REQUEST_BATCH_IMAGES = 'ImagesAction.REQUEST_BATCH_IMAGES';
    static REQUEST_BATCH_IMAGES_FINISHED = 'ImagesAction.REQUEST_BATCH_IMAGES_FINISHED';

    static REQUEST_PRIMARY_COLLECTION = 'ImageGalleryAction.REQUEST_PRIMARY_COLLECTION';
    static REQUEST_PRIMARY_COLLECTION_FINISHED = 'ImageGalleryAction.REQUEST_PRIMARY_COLLECTION_FINISHED';

    static selectImage(data) {
        return ActionUtility.createAction(ImageGalleryAction.SELECT_IMAGE, data);
    }

    static clearImages() {
        return ActionUtility.createAction(ImageGalleryAction.CLEAR_IMAGES);
    }

    static requestBatchImages(data) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(
                dispatch,
                ImageGalleryAction.REQUEST_BATCH_IMAGES,
                ImageGalleryEffect.requestBatchImages,
                { notify: false },
                data
            );
        };
    }

    static requestPrimaryCollection(data) {
        return async (dispatch, getState) => {
            data = {
                recipe_id: data,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                ImageGalleryAction.REQUEST_PRIMARY_COLLECTION,
                ImageGalleryEffect.requestPrimaryCollection,
                { notify: false },
                data
            );
        };
    }

    static addImageToRecipe(data) {
        return async (dispatch, getState) => {
            data = { ...data, user: getState().auth };

            await ActionUtility.createThunkEffect(
                dispatch,
                ImageGalleryAction.REQUEST_IMAGE_ADD_TO_RECIPE,
                ImageGalleryEffect.addImageToRecipe,
                { notify: true, title: 'Saving image to recipe' },
                data
            );
        };
    }

    static requestImageDeletion(id) {
        return async (dispatch, getState) => {
            const data = {
                image_id: id,
                invoker_id: getState().auth.user_id,
            };

            await ActionUtility.createThunkEffect(
                dispatch,
                ImageGalleryAction.REQUEST_IMAGE_DELETION,
                ImageGalleryEffect.deleteImage,
                { notify: true, title: 'Deleting image' },
                data
            );
        };
    }
}
