import RecipesAction from './RecipesAction';
import BaseReducer from '../../utilities/BaseReducer';
import ImageGalleryAction from '../image-gallery/ImageGalleryAction';

export default class RecipesReducer extends BaseReducer {
    initialState = {
        updating: false,
        recipe_id: '',
        title: '',
        alt_title: '',
        description: '',
        servings: [],
        prep_info: [],
        ingredients: [],
        directions: [],
        nutrients: [],
        chef_tips: [],
        keywords: {
            tags: [],
            suggestions: [
                { id: 'Dinner', text: 'Dinner' },
                { id: 'Vegetarian', text: 'Vegetarian' },
                { id: 'Snack', text: 'Snack' },
                { id: 'Appetizer', text: 'Appetizer' },
                { id: 'Drink', text: 'Drink' },
                { id: 'Vegan', text: 'Vegan' },
            ],
        },
        image_names: [],
        comments: [],
        images: [],
        state: '',
        sales_team_export_path: '',
        stateLog: [],
        archived: false,
    };

    [RecipesAction.ADD_RECORD](state, action) {
        const data = action.payload;

        function getRecord(attribute) {
            switch (attribute) {
                case 'servings':
                    return {
                        prefix: '',
                        quantity: '',
                        suffix: '',
                        position: state.servings[data.listId].records.length + 1,
                    };
                case 'prep_info':
                    return {
                        type: '',
                        quantity: '',
                        unit: '',
                        suffix: '',
                        position: state.prep_info[data.listId].records.length + 1,
                    };
                case 'ingredients':
                    return {
                        unit: '',
                        quantity: '',
                        ingredient: '',
                        position: state.ingredients[data.listId].records.length + 1,
                    };
                case 'directions':
                    return {
                        description: '',
                        position: state.directions[data.listId].records.length + 1,
                    };
                case 'chef_tips':
                    return {
                        description: '',
                        position: state.chef_tips[data.listId].records.length + 1,
                    };
            }
        }

        return {
            ...state,
            [data.attribute]: state[data.attribute].map((list, index) => {
                if (index !== +data.listId) {
                    return { ...list };
                }

                return {
                    ...list,
                    records: [...list.records, getRecord(data.attribute)],
                };
            }),
        };
    }

    [RecipesAction.ADD_KEYWORD](state, action) {
        const tag = action.payload.tag;

        return {
            ...state,
            keywords: {
                ...state.keywords,
                tags: [
                    ...state.keywords.tags,
                    {
                        id: tag.id,
                        text: tag.text,
                    },
                ],
            },
        };
    }

    [RecipesAction.HANDLE_IMAGE_NAME_CHANGE](state, action) {
        const index = action.payload.key;
        const name = action.payload.value;
        console.log(action.payload);

        return {
            ...state,
            image_names: state.image_names.map((record) => {
                if (record.key !== index) {
                    return { ...record };
                } else {
                    return {
                        ...record,
                        name: name,
                    };
                }
            }),
        };
    }

    [RecipesAction.DELETE_KEYWORD](state, action) {
        const tagIndex = action.payload.index;

        return {
            ...state,
            keywords: {
                ...state.keywords,
                tags: state.keywords.tags.filter((tag, index) => {
                    return index !== tagIndex;
                }),
            },
        };
    }

    [RecipesAction.SELECT_RECORD](state, action) {
        const data = action.payload;
        const attribute = data.attribute;

        return {
            ...state,
            [attribute]: state[attribute].map((list, index) => {
                if (index !== +data.listId) {
                    return { ...list };
                }

                return {
                    ...list,
                    records: list.records.map((record, index) => {
                        if (index !== +data.id) {
                            return { ...record };
                        }

                        return {
                            ...record,
                            selected: !record.selected,
                        };
                    }),
                };
            }),
        };
    }

    [RecipesAction.SELECT_LIST](state, action) {
        const data = action.payload;
        const attribute = data.attribute;

        return {
            ...state,
            [attribute]: state[attribute].map((list, index) => {
                if (index !== +data.listId) {
                    return { ...list };
                }

                return {
                    ...list,
                    selected: !list.selected,
                };
            }),
        };
    }

    [RecipesAction.ADD_LIST](state, action) {
        const attribute = action.payload.attribute;

        function getRecord(attribute) {
            switch (attribute) {
                case 'servings':
                    return {
                        list_name: '',
                        records: [],
                    };
                case 'prep_info':
                    return {
                        list_name: '',
                        records: [],
                    };
                case 'ingredients':
                    return {
                        list_name: '',
                        records: [],
                    };
                case 'directions':
                    return {
                        list_name: '',
                        records: [],
                    };
                case 'chef_tips':
                    return {
                        list_name: '',
                        records: [],
                    };
                case 'nutrients':
                    return {
                        list_name: '',
                        add_nutrient: '',
                        serving_size: '',
                        records: [
                            {
                                nutrient: 'Calories',
                                quantity: '',
                                unit: '',
                            },
                            {
                                nutrient: 'Fat',
                                quantity: '',
                                unit: 'g',
                            },
                            {
                                nutrient: 'Saturated Fat',
                                quantity: '',
                                unit: 'g',
                            },
                            {
                                nutrient: 'Cholesterol',
                                quantity: '',
                                unit: 'mg',
                            },
                            {
                                nutrient: 'Sodium',
                                quantity: '',
                                unit: 'mg',
                            },
                            {
                                nutrient: 'Carbohydrates',
                                quantity: '',
                                unit: 'g',
                            },
                            {
                                nutrient: 'Fiber',
                                quantity: '',
                                unit: 'g',
                            },
                            {
                                nutrient: 'Sugars',
                                quantity: '',
                                unit: 'g',
                            },
                            {
                                nutrient: 'Added Sugars',
                                quantity: '',
                                unit: 'g',
                            },
                            {
                                nutrient: 'Protein',
                                quantity: '',
                                unit: 'g',
                            },
                        ],
                    };
            }
        }

        return {
            ...state,
            [attribute]: [...state[attribute], getRecord(attribute)],
        };
    }

    [RecipesAction.DELETE_LISTS](state, action) {
        return {
            ...state,
            servings: state.servings.filter((list) => {
                return list.selected !== true;
            }),
            prep_info: state.prep_info.filter((list) => {
                return list.selected !== true;
            }),
            ingredients: state.ingredients.filter((list) => {
                return list.selected !== true;
            }),
            directions: state.directions.filter((list) => {
                return list.selected !== true;
            }),
            chef_tips: state.chef_tips.filter((list) => {
                return list.selected !== true;
            }),
            nutrients: state.nutrients.filter((list) => {
                return list.selected !== true;
            }),
        };
    }

    [RecipesAction.DELETE_RECORDS](state, action) {
        return {
            ...state,
            servings: state.servings.map((list) => {
                return {
                    ...list,
                    records: list.records.filter(function(record) {
                        return record.selected !== true;
                    }),
                };
            }),
            prep_info: state.prep_info.map((list) => {
                return {
                    ...list,
                    records: list.records.filter(function(record) {
                        return record.selected !== true;
                    }),
                };
            }),
            ingredients: state.ingredients.map((list) => {
                return {
                    ...list,
                    records: list.records.filter(function(record) {
                        return record.selected !== true;
                    }),
                };
            }),
            directions: state.directions.map((list) => {
                return {
                    ...list,
                    records: list.records.filter(function(record) {
                        return record.selected !== true;
                    }),
                };
            }),
            chef_tips: state.chef_tips.map((list) => {
                return {
                    ...list,
                    records: list.records.filter(function(record) {
                        return record.selected !== true;
                    }),
                };
            }),
            nutrients: state.nutrients.map((list) => {
                return {
                    ...list,
                    records: list.records.filter(function(record) {
                        return record.selected !== true;
                    }),
                };
            }),
        };
    }

    [RecipesAction.ADD_NUTRIENT_RECORD](state, action) {
        const data = action.payload;
        const nutrient = state.nutrients[data.listId].add_nutrient;

        return {
            ...state,
            nutrients: state.nutrients.map((list, index) => {
                if (index !== +data.listId) {
                    return { ...list };
                }

                return {
                    ...list,
                    add_nutrient: '',
                    records: [
                        ...list.records,
                        {
                            nutrient: nutrient,
                            quantity: '',
                            unit: '',
                        },
                    ],
                };
            }),
        };
    }

    [RecipesAction.HANDLE_RECIPE_CHANGE](state, action) {
        const data = action.payload;
        return {
            ...state,
            [data.key]: data.value,
        };
    }

    [RecipesAction.REORDER_LIST](state, action) {
        const data = action.payload;

        return {
            ...state,
            [data.attribute]: state[data.attribute].map((list, index) => {
                if (index !== +data.listId) {
                    return { ...list };
                }

                return {
                    ...list,
                    records: list.records.map((record, index) => {
                        return {
                            ...record,
                            position: data.order[index],
                        };
                    }),
                };
            }),
        };
    }

    [RecipesAction.HANDLE_LIST_CHANGE](state, action) {
        const data = action.payload;
        const attribute = data.attribute;

        return {
            ...state,
            [attribute]: state[attribute].map((list, index) => {
                if (index !== +data.listId) {
                    return { ...list };
                }

                return {
                    ...list,
                    [data.key]: data.value,
                };
            }),
        };
    }

    [RecipesAction.HANDLE_NESTED_CHANGE](state, action) {
        const data = action.payload;
        const attribute = data.attribute;
        return {
            ...state,
            [attribute]: state[attribute].map((list, index) => {
                if (index !== +data.listId) {
                    return { ...list };
                }

                return {
                    ...list,
                    records: list.records.map((record, index) => {
                        if (index !== +data.id) {
                            return { ...record };
                        }

                        return {
                            ...record,
                            [data.key]: data.value,
                        };
                    }),
                };
            }),
        };
    }

    [RecipesAction.REQUEST_SINGLE_RECIPE_FINISHED](state, action) {
        function parseData(data) {
            if (data) {
                return JSON.parse(data);
            } else {
                return [];
            }
        }

        const recipe = action.payload.recipe;

        const servings = parseData(recipe.servings);
        const prep_info = parseData(recipe.prep_info);
        const ingredients = parseData(recipe.ingredients);
        const directions = parseData(recipe.directions);
        const nutrients = parseData(recipe.nutrients);
        const chef_tips = parseData(recipe.chef_tips);
        const keywords = parseData(recipe.keywords);
        const image_names = parseData(recipe.image_names);
        const comments = parseData(recipe.comments);

        return {
            ...state,
            recipe_id: recipe.recipe_id,
            title: recipe.title,
            alt_title: recipe.alt_title,
            description: recipe.description ? recipe.description : '',
            servings: servings.map((list) => {
                return {
                    list_name: list.list_name,
                    records: list.records.map((record, index) => {
                        return {
                            prefix: record.prefix,
                            quantity: record.quantity,
                            suffix: record.suffix,
                            position: index,
                        };
                    }),
                };
            }),
            prep_info: prep_info.map((list) => {
                return {
                    list_name: list.list_name,
                    records: list.prep_info_records.map((record, index) => {
                        return {
                            type: record.type,
                            quantity: record.quantity,
                            unit: record.unit,
                            suffix: record.suffix,
                            position: index,
                        };
                    }),
                };
            }),
            ingredients: ingredients.map((list) => {
                return {
                    list_name: list.list_name,
                    records: list.ingredient_records.map((record, index) => {
                        return {
                            unit: record.unit,
                            quantity: record.quantity,
                            ingredient: record.ingredient,
                            position: index,
                        };
                    }),
                };
            }),
            directions: directions.map((list) => {
                return {
                    list_name: list.section,
                    records: list.steps.map((record, index) => {
                        return {
                            description: record,
                            position: index,
                        };
                    }),
                };
            }),
            nutrients: nutrients.map((list) => {
                return {
                    list_name: list.list_name,
                    serving_size: list.serving_size,
                    add_nutrient: '',
                    records: list.nutrient_records.map((record) => {
                        return {
                            nutrient: record.nutrient,
                            quantity: record.quantity,
                            unit: record.unit,
                        };
                    }),
                };
            }),
            chef_tips: chef_tips.map((list) => {
                return {
                    list_name: list.list_name,
                    records: list.records.map((record, index) => {
                        return {
                            description: record,
                            position: index,
                        };
                    }),
                };
            }),
            keywords: {
                ...state.keywords,
                tags: keywords.map((keyword) => {
                    return {
                        id: keyword,
                        text: keyword,
                    };
                }),
            },
            image_names: image_names.map((name, key) => {
                return {
                    key: key,
                    name: name,
                };
            }),
            sales_team_export_path: '',
            comments: comments.map((record) => {
                return {
                    comment: record.comment,
                    userId: record.user_id,
                    time: record.timestamp,
                };
            }),
            state: recipe.state,
            archived: recipe.archived,
        };
    }

    [RecipesAction.REQUEST_STATE_LOG_FINISHED](state, action) {
        return {
            ...state,
            stateLog: action.payload.log,
        };
    }

    [RecipesAction.EXPORT_SALES_TEAM_FINISHED](state, action) {
        return {
            ...state,
            sales_team_export_path: action.payload.path,
        };
    }

    [ImageGalleryAction.DELETE_IMAGE_FINISHED](state, action) {
        const data = action.payload.image_id;
        return {
            ...state,
            images: state.images.filter((image) => {
                return image.image_id !== data;
            }),
        };
    }
}
