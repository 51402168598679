import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import AddImageToRecipeResponse from './models/AddImageToRecipeResponse';
import DeleteImageResponse from './models/DeleteImageResponse';
import PrimaryCollection from './models/PrimaryCollection';
import Image from './models/Image';

export default class ImageGalleryEffect {
    static async requestPrimaryCollection(data) {
        const endpoint = environment.api.recipeSearch.replace(':endpoint', 'primary-collection');

        return EffectUtility.getToModel(PrimaryCollection, endpoint, data);
    }

    static async requestBatchImages(data) {
        const endpoint = environment.api.recipeSearch.replace(':endpoint', 'images');

        const imageIds = {
            image_ids: data,
        };

        return EffectUtility.postToModel(Image, endpoint, imageIds);
    }

    static async addImageToRecipe(data) {
        const endpoint = environment.api.saga.replace(':saga', 'add-image-from-edit-recipe-page');

        const addImageToRecipeRequest = {
            image: data.image,
            invoker_id: data.user.user_id,
            image_size: data.image_size,
            recipe_id: data.recipe_id,
        };

        return EffectUtility.postToModel(AddImageToRecipeResponse, endpoint, addImageToRecipeRequest);
    }

    static async deleteImage(data) {
        const endpoint = environment.api.deleteImage;

        return EffectUtility.postToModel(DeleteImageResponse, endpoint, data);
    }
}
