import AuthAction from './AuthAction';
import BaseReducer from '../../utilities/BaseReducer';

export default class AuthReducer extends BaseReducer {
    initialState = {
        is_authenticated: false,
        email: '',
        password: '',
        api_token: '',
        mock_org_id: '',
        user_id: '',
    };

    [AuthAction.HANDLE_FORM_CHANGE](state, action) {
        const data = action.payload;
        return {
            ...state,
            [data.key]: data.value,
        };
    }

    [AuthAction.HANDLE_MOCK_ORG_CHANGE](state, action) {
        const data = action.payload;

        return {
            ...state,
            mock_org_id: data,
        };
    }

    [AuthAction.REQUEST_USER_AUTHENTICATION_FINISHED](state, action) {
        const data = action.payload;

        return {
            ...state,
            is_authenticated: data.success,
            password: '',
            user_id: data.user_id,
            organization_id: data.organization_id,
            api_token: data.api_token,
        };
    }

    [AuthAction.REQUEST_TOKEN_VALIDATION_FINISHED](state, action) {
        const data = action.payload;

        return {
            ...state,
            is_authenticated: data.success,
            user_id: data.user_id,
            email: data.email,
            organization_id: data.organization_id,
            api_token: data.api_token,
        };
    }
}
