import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
        "id": 1,
        "name": "Alex",
        "email": "alennard@vimaxmedia.com",
        "api_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ2aW1heC1jb250ZW50LXBybyIsInN1YiI6MSwiaWF0IjoxNTg5OTE0NjUzLCJleHAiOjE1OTAxMzA2NTN9.mZyMcKsv3yWt0-xb3adRT9-o0nlERNfkNhRiMudo-M4",
        "created_at": null,
        "updated_at": "2020-05-19 13:57:33"
    }
 */
export default class ValidateTokenResponse extends BaseModel {
    success = '';
    user_id = '';
    email = '';
    organization_id = '';
    api_token = '';

    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
