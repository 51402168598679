import React from 'react';
import PropTypes from 'prop-types';
import './NavbarDesktop.scss';
import { Link } from 'react-router-dom';
import NavbarLogo from '../../../../images/vimax-logo.png';

const NavbarDesktopView = (props) => {
    return (
        <div className={'navbar-wrapper'}>
            <div className={'navbar-top'}>
                <img className={'logo'} src={NavbarLogo} />
                <div className={'links'}>
                    <Link to={'/recipes'}>Recipe Search</Link>
                    <Link to={'/collections'}>Collection Search</Link>
                </div>
            </div>
        </div>
    );
};

NavbarDesktopView.propTypes = {
    navbarData: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ),
};

export default NavbarDesktopView;
